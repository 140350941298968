import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { PostgrestError, User } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";
import {
  updateAccountProfile as updateAccountProfileAction,
  updateActiveOrgId,
} from "store/accountProfileSlice";
import store from "store/store";

import { FrameShape } from "components/shared/library/Avatar";

import { supabase } from "apis/supabaseClient";

import { useRealtimeChannelHealth } from "utils/realtime";
import { handleValidator } from "utils/validator";

import { Org } from "./orgs";
import { ProfileColor } from "./posts";
import { UserInfo } from "./user";

export type UserPlan = "basic" | "pro";

export type AccountProfile = UserInfo & {
  locationName?: string;
  orgs: Pick<Org, "id" | "name" | "photoUrl" | "isVerified">[];
  isVerified: boolean;
  plan: UserPlan;
  teamHasRecruitPaymentSetup: boolean;
};

type dbAccountProfile = {
  id: string;
  avatar_url: string;
  shape: string;
  color: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  headline: string;
  handle: string;
  active_org_id: string;
  location_name: string;
  orgs?: {
    id: string;
    orgName: string;
    orgPhotoUrl: string;
    orgIsVerified: boolean;
  }[];
  playback_id: string;
  is_verified: boolean;
  plan: UserPlan;
  team_has_recruit_payment_setup: boolean;
};

export const getAccountProfile = async (): Promise<AccountProfile> => {
  const { data, error }: { data: unknown; error: PostgrestError | null } =
    await supabase.rpc("get_account_profile");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  const dbData = data as dbAccountProfile;
  const accountProfile: AccountProfile = {
    id: dbData.id,
    avatarUrl: dbData.avatar_url,
    color: dbData.color as ProfileColor,
    fullName: dbData.name,
    firstName: dbData.first_name,
    lastName: dbData.last_name,
    shape: dbData.shape as FrameShape,
    headline: dbData.headline,
    handle: dbData.handle,
    activeOrgId: dbData.active_org_id,
    email: dbData.email,
    locationName: dbData.location_name,
    playbackId: dbData.playback_id,
    orgs:
      dbData.orgs
        ?.filter((org) => Object.keys(org).length)
        .map(({ id, orgName, orgPhotoUrl, orgIsVerified }) => ({
          id,
          name: orgName,
          photoUrl: orgPhotoUrl,
          isVerified: orgIsVerified,
        })) || [],
    isVerified: dbData.is_verified,
    plan: dbData.plan,
    teamHasRecruitPaymentSetup: dbData.team_has_recruit_payment_setup,
  };

  return accountProfile;
};

export const getAllUnavailableLowerCaseHandles = async (
  userId: string
): Promise<string[]> => {
  const { data, error } = await supabase
    .from("user_public_profiles")
    .select("handle")
    .neq("id", userId);

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map(({ handle }) => handle.toLowerCase());
};

export const updateAccountProfile = async (
  userId: string,
  updates: {
    shape: FrameShape;
    color: ProfileColor;
    firstName: string;
    lastName: string;
    photoUrl: string | null;
    headline?: string;
    handle: string;
  }
): Promise<{ error: PostgrestError | null }> => {
  // These validations should be happening backend, but since we are updating the db
  // directly here, we check again here instead
  const unavailableHandles = await getAllUnavailableLowerCaseHandles(userId);
  if (unavailableHandles.includes(updates.handle))
    throw new Error("Handle already taken");
  if (!handleValidator(updates.handle)) throw new Error("Invalid handle");
  if (`${updates.firstName} ${updates.lastName}`.trim().length > 50)
    throw new Error("Full name is too long");
  if ((updates.headline?.length || 0) > 160)
    throw new Error("headline is too long");

  const { error } = await supabase
    .from("user_public_profiles")
    .update({
      photo_url: updates.photoUrl,
      first_name: updates.firstName,
      last_name: updates.lastName,
      color: updates.color,
      shape: updates.shape,
      headline: updates.headline,
      handle: updates.handle,
    })
    .eq("id", userId);

  store.dispatch(updateAccountProfileAction(await getAccountProfile()));

  return { error };
};

export const useActiveOrg = (userId?: string) => {
  const dispatch = useDispatch();

  const setActiveOrg = async (orgId: string) => {
    if (!userId) return;

    dispatch(updateActiveOrgId(orgId));
    await supabase
      .from("user_private_profiles")
      .update({
        active_org_id: orgId,
      })
      .eq("id", userId);
  };
  return setActiveOrg;
};

export const useAccountProfileRealtime = (
  user: User | undefined
): { isLoaded: boolean } => {
  const [isLoaded, setIsLoaded] = useState(false);

  const dispatch = useDispatch();

  const accountProfileChannelName = "db-account-profile";
  const isChannelHealthy = useRealtimeChannelHealth(
    accountProfileChannelName,
    !!user
  );

  useEffect(() => {
    if (!user || (isLoaded && isChannelHealthy)) return;

    const channel = supabase.channel(accountProfileChannelName);

    if (user?.id) {
      const updateAccountProfileOnUpdate = (
        table: string,
        columnName: string,
        columnValue: string
      ) => {
        channel.on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table,
            filter: `${columnName}=eq.${columnValue}`,
          },
          async () => {
            const accountProfile = await getAccountProfile();
            dispatch(updateAccountProfileAction(accountProfile));
          }
        );
      };

      updateAccountProfileOnUpdate("user_private_profiles", "id", user.id);
      updateAccountProfileOnUpdate("user_public_profiles", "id", user.id);
      updateAccountProfileOnUpdate("org_roles", "user_id", user.id);
      updateAccountProfileOnUpdate(
        "stripe_id_verification",
        "user_id",
        user.id
      );
      // update account when user changes team
      updateAccountProfileOnUpdate(
        "recruiting_team_member",
        "recruiter_id",
        user.id
      );

      channel.on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "org_roles",
          filter: `user_id=eq.${user.id}`,
        },
        async () => {
          const accountProfile = await getAccountProfile();
          dispatch(updateAccountProfileAction(accountProfile));
        }
      );

      // N.B. we are not subscribed to orgs table because
      // we don't know the org IDs
      // this means we don't receive updated org names
      channel.subscribe(async (status) => {
        if (status === "SUBSCRIBED") {
          const accountProfile = await getAccountProfile();
          dispatch(updateAccountProfileAction(accountProfile));
          setIsLoaded(true);
        }
      });
    } else {
      dispatch(updateAccountProfileAction(undefined));
    }
  }, [isLoaded, dispatch, user, isChannelHealthy]);

  return { isLoaded };
};
