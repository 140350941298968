import { createSlice } from "@reduxjs/toolkit";

import { UserPlan } from "apis/accountProfile";
import { PlanLimits } from "apis/ribbonPro";

import { Action, State } from "./store";

export const planSlice = createSlice({
  name: "plan",
  initialState: {
    value: {} as Record<UserPlan, PlanLimits>,
  },
  reducers: {
    addPlan: (state, action: Action<PlanLimits>) => {
      state.value[action.payload.id] = action.payload;
    },
  },
});

export const { addPlan } = planSlice.actions;

export const selectPlanLimits =
  (planId: UserPlan) =>
  (state: State): PlanLimits | undefined => {
    return state.plan.value[planId];
  };

export default planSlice.reducer;
