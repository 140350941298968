import { createSlice } from "@reduxjs/toolkit";

import { Action, State } from "./store";

export const practiceRoundSlice = createSlice({
  name: "practiceRound",
  initialState: {
    value: { seenCount: undefined } as { seenCount?: number },
  },
  reducers: {
    addPracticeRoundSeenCount: (state, action: Action<number>) => {
      state.value.seenCount = action.payload;
    },
  },
});

export const { addPracticeRoundSeenCount } = practiceRoundSlice.actions;

export const selectPracticeRoundSeenCount = (
  state: State
): number | undefined => {
  return state.practiceRound.value.seenCount;
};

export default practiceRoundSlice.reducer;
