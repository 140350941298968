import { IconProps } from "Icons/types";

export const HeartSmileyColorPeelIcon = ({
  width = 56,
  height = 56,
  fill = "#F5819D",
  stroke = "#F34479",
  strokeWidth = 2.27027,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="HeartSmileyColorPeelIcon"
  >
    <g filter="url(#filter0_dd_2227_536286)">
      <path
        d="M4.767 18.5987C5.87375 11.1395 12.7753 3.99153 21.281 5.25355C26.1644 5.97811 28.9952 8.89357 30.5281 11.3148C32.698 9.44296 36.2532 7.47501 41.1366 8.19958C44.8966 9.99466 52.546 16.0208 53.0644 25.7647C51.2669 37.8799 34.1983 45.9302 27.5093 48.6433C25.8403 49.3202 23.9788 49.044 22.5783 47.9116C16.9652 43.3733 2.96944 30.7139 4.767 18.5987Z"
        fill={fill}
      />
      <path
        d="M31.2695 12.1743L30.273 13.034L29.569 11.922C28.1534 9.68616 25.5757 7.03832 21.1144 6.37639C13.3929 5.23073 6.93098 11.7483 5.88984 18.7653C5.06039 24.3557 7.87245 30.2303 11.7984 35.4109C15.6945 40.5521 20.5167 44.785 23.292 47.0289C24.3715 47.9017 25.7963 48.1131 27.0827 47.5914C30.3899 46.2499 36.2335 43.5996 41.4545 39.811C46.6802 36.0191 51.0532 31.2528 51.9244 25.7104C51.3907 16.7429 44.3981 11.08 40.8005 9.29836C36.4268 8.70586 33.2478 10.4678 31.2695 12.1743Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </g>
    <path
      d="M47.1068 14.4802C45.2342 12.2172 43.2296 10.6684 41.7908 9.88941C41.6034 10.6017 41.3936 11.5906 41.2908 12.7266C41.079 15.0682 41.3324 17.8992 43.0108 20.2615C44.6943 22.6313 47.1481 23.7361 49.2631 24.2424C50.2365 24.4754 51.118 24.5762 51.7826 24.617C51.1499 20.5232 49.2578 17.0798 47.1068 14.4802Z"
      fill="white"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M33.9852 26.635C32.1159 26.3577 30.8254 24.6175 31.1027 22.7482C31.3801 20.8789 33.1203 19.5884 34.9895 19.8658C36.8588 20.1431 38.1493 21.8833 37.872 23.7526C37.5946 25.6219 35.8544 26.9124 33.9852 26.635Z"
      fill="white"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M22.3825 24.9133C20.5132 24.636 19.2227 22.8958 19.5001 21.0265C19.7774 19.1573 21.5176 17.8668 23.3869 18.1441C25.2562 18.4215 26.5467 20.1616 26.2693 22.0309C25.992 23.9002 24.2518 25.1907 22.3825 24.9133Z"
      fill="white"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M31.0411 30.3102C32.0346 29.1031 33.8217 28.9364 35.0433 29.8807C36.2851 30.8405 36.5238 32.6244 35.5119 33.8538C34.4424 35.1531 33.0395 36.1502 31.4468 36.7497C29.8544 37.3492 28.1258 37.5311 26.4333 37.28C24.7408 37.0289 23.1396 36.3529 21.7898 35.3169C20.4398 34.2807 19.3868 32.9193 18.7406 31.3654C18.1293 29.8952 18.8757 28.2574 20.3426 27.6995C21.7858 27.1506 23.4475 27.829 24.0478 29.2725C24.3115 29.9068 24.7445 30.47 25.3078 30.9023C25.8713 31.3348 26.5436 31.6197 27.2574 31.7256C27.9712 31.8315 28.6973 31.7541 29.3621 31.5039C30.0267 31.2537 30.6045 30.8405 31.0411 30.3102Z"
      fill="white"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_dd_2227_536286"
        x="2.33849"
        y="4.35067"
        width="52.9962"
        height="48.4419"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="0.756757"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_2227_536286"
        />
        <feOffset dy="0.756757" />
        <feGaussianBlur stdDeviation="0.756757" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2227_536286"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="0.756757"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_2227_536286"
        />
        <feOffset dy="1.51351" />
        <feGaussianBlur stdDeviation="1.51351" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2227_536286"
          result="effect2_dropShadow_2227_536286"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2227_536286"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
