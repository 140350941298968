import { createSlice } from "@reduxjs/toolkit";

import { ResumeFeedback } from "apis/resumeFeedback";

import { Action, State } from "./store";

export const resumeFeedback = createSlice({
  name: "resumeFeedback",
  initialState: {
    latest: undefined as ResumeFeedback | undefined,
    counts: { seenCount: undefined } as { seenCount?: number },
  },
  reducers: {
    addResumeFeedbackSeenCount: (state, action: Action<number | undefined>) => {
      state.counts.seenCount = action.payload;
    },
    addResumeFeedback: (state, action: Action<ResumeFeedback | undefined>) => {
      state.latest = action.payload;
    },
    seeFeedback: (
      state,
      action: Action<{ filename: string; userId: string }>
    ) => {
      if (!state.latest) return;
      const wasSeen = state.latest?.seenBy === action.payload.userId;
      if (wasSeen) return;
      // wark as seen
      state.latest = { ...state.latest, seenBy: action.payload.userId };
      // increment seen count
      state.counts.seenCount = (state.counts.seenCount || 0) + 1;
    },
  },
});

export const { addResumeFeedbackSeenCount, addResumeFeedback, seeFeedback } =
  resumeFeedback.actions;

export const selectResumeFeedbackSeenCount = (
  state: State
): number | undefined => {
  return state.resumeFeedback.counts.seenCount;
};

export const selectResumeFeedback = (
  state: State
): ResumeFeedback | undefined => {
  return state.resumeFeedback.latest;
};

export default resumeFeedback.reducer;
