import { Edit05Icon } from "Icons/Edit05";
import { LifeBuoy02Icon } from "Icons/LifeBuoy02";
import { Mail02Icon } from "Icons/Mail02";

import { css } from "@emotion/react";

import Link from "next/link";

import { Typography } from "components/shared/library/Typography";

import { purple, uiGray } from "constants/colors";

import { ButtonWrapper } from "../library/ButtonWrapper";

const style = {
  title: css({
    padding: 12,
  }),
  content: css({
    padding: 4,
  }),
  menuBtn: css({
    padding: "8px 12px",
    "&:hover": { backgroundColor: purple[50], borderRadius: 8 },
    width: "-webkit-fill-available",
    display: "flex",
    gap: 8,
    alignItems: "center",
  }),
  link: css({
    textDecoration: "inherit",
  }),
  menuItem: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  divider: css({
    margin: "16px -4px 4px -4px",
  }),
  slackContainer: css({
    margin: "0px 12px",
    padding: 16,
    background: purple[100],
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
  }),
  slackTitle: css({
    margin: "8px 0px 4px",
  }),
};

export const SupportMenu = ({ onClick }: { onClick: () => void }) => {
  return (
    <div css={style.content}>
      <Typography
        tag="p"
        weight="medium"
        color={uiGray[60]}
        size="XXS"
        customCss={style.title}
      >
        How can we help you?
      </Typography>
      <div css={style.menuItem}>
        <ButtonWrapper
          onClick={onClick}
          customCss={style.menuBtn}
          ariaLabel="Provide feedback"
        >
          <Edit05Icon width={16} height={16} stroke={uiGray[90]} />
          <Typography weight="medium" size="XS">
            Provide feedback
          </Typography>
        </ButtonWrapper>
        <Link href={"mailto:support@ribbon.ai"} css={style.link}>
          <div css={style.menuBtn}>
            <Mail02Icon width={16} height={16} stroke={uiGray[90]} />
            <Typography weight="medium" size="XS">
              Email us
            </Typography>
          </div>
        </Link>
        <Link href={"https://www.ribbon.ai/faq"} css={style.link}>
          <div css={style.menuBtn}>
            <LifeBuoy02Icon width={16} height={16} stroke={uiGray[90]} />
            <Typography weight="medium" size="XS">
              Get help
            </Typography>
          </div>
        </Link>
      </div>
    </div>
  );
};
