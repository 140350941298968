import { XCloseIcon } from "Icons/XClose";

import { useMemo, useState } from "react";

import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { useAuth } from "contexts/authContext";

import { up } from "utils/mediaQueryStrings";
import {
  getCheckEmailForResetPasswordUrl,
  getEmailVerifiedUrl,
  getForgotPasswordUrl,
  getInterviewUrl,
  getInvitationUrl,
  getRecruitUrl,
  getResetPasswordUrl,
  getSignInUrl,
  getSignUpUrl,
  getVerifyEmailUrl,
} from "utils/urls";

import { purple, uiGray } from "constants/colors";

import { Button } from "../library/Button";
import { Typography } from "../library/Typography";

const styles = {
  bottomBannerContainer: css({
    position: "fixed",
    width: "100vw",
    justifyContent: "space-between",
    display: "flex",
    bottom: 0,
    left: 0,
    background: `linear-gradient(98.01deg, #7766FF 0%, ${purple[600]} 50%, #3E22C5 100%)`,
    boxShadow: "0px -17px 9px -12px rgba(27, 27, 27, 0.03);",
    borderRadius: "12px 12px 0px 0px;",
    zIndex: 0,
    [up("mobile")]: {
      bottom: 40,
      right: 40,
      left: "unset",
      borderRadius: "12px 12px 12px 12px;",
      width: 346,
      maxWidth: 346,
    },
  }),
  headerContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: 5,
  }),
  leftContent: css({
    width: 300,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: 30,
  }),
  rightContent: css({
    position: "relative",
    right: 16,
    top: 16,
  }),

  closeButton: css({ padding: "10px 5px" }),
  buttons: css({
    display: "flex",
    gap: 16,
  }),
};

export const SignupBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const router = useRouter();
  const { user } = useAuth();

  const pathsWithoutSignupBanner = useMemo(
    () => [
      getSignInUrl({}),
      getSignInUrl({ currentPath: getRecruitUrl() }),
      getSignInUrl({ currentPath: getInterviewUrl() }),
      getSignUpUrl({}),
      getSignUpUrl({ currentPath: getRecruitUrl() }),
      getSignUpUrl({ currentPath: getInterviewUrl() }),
      getEmailVerifiedUrl({}),
      getEmailVerifiedUrl({ currentPath: getRecruitUrl() }),
      getEmailVerifiedUrl({ currentPath: getInterviewUrl() }),
      getVerifyEmailUrl(),
      getInvitationUrl(),
      getResetPasswordUrl(),
      getCheckEmailForResetPasswordUrl(),
      getForgotPasswordUrl(),
      getRecruitUrl(),
      getInterviewUrl(),
    ],
    []
  );

  if (pathsWithoutSignupBanner.some((path) => router.pathname.includes(path))) {
    return null;
  }

  if (!showBanner || user) return null;

  return (
    <div css={styles.bottomBannerContainer}>
      <div css={styles.leftContent}>
        <div css={styles.headerContainer}>
          <Typography weight="bold" color="white">
            Land your dream job
          </Typography>
          <Typography tag="p" color={uiGray[30]}>
            Use Ribbon&apos;s AI-powered tools to remove roadblocks in your job
            search.
          </Typography>
        </div>
        <div css={styles.buttons}>
          <Button
            size="small"
            color="black"
            onClick={() => router.push("/sign-up")}
          >
            Sign up now
          </Button>
          <Button
            size="small"
            color="gray"
            onClick={() => router.push("/sign-in")}
          >
            Log in
          </Button>
        </div>
      </div>
      <div css={styles.rightContent}>
        <Button
          ariaLabel="Close"
          color="purple"
          size="small"
          customCss={{ button: styles.closeButton }}
          onClick={() => setShowBanner(false)}
          testId="close-button"
        >
          <XCloseIcon stroke="white" />
        </Button>
      </div>
    </div>
  );
};
