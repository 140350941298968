import { logger } from "interfaces/logger";
import store from "store/store";
import { addSysAdminRoles } from "store/sysAdminSlice";

import { supabase } from "./supabaseClient";

export const getSysAdminRoles = async () => {
  const { data, error } = await supabase.rpc("get_sys_admin_roles");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  if (!data || data?.length === 0) {
    return;
  }

  store.dispatch(addSysAdminRoles(data[0].sys_admin_roles));
};
