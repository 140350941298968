import { css } from "@emotion/react";

import { gradient } from "constants/colors";

import { LoadingCard } from "./LoadingCard";

const style = {
  background: css({
    background: gradient.background,
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
};

export const LoadingFullScreen = () => {
  return (
    <div css={style.background}>
      <LoadingCard />
    </div>
  );
};
