import { keyframes } from "@emotion/react";

export const SLIDE_TRANSITION_DURATION = "0.2s";
export const SLIDE_TRANSITION_TIMING_FUNCTION = "ease-in-out";

export const ROTATE_KEYFRAME = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const ROTATE_TRANSITION_DURATION = "1s";
export const ROTATE_TRANSITION_TIMING_FUNCTION = "linear";

export const ROTATE_ICON_ANIMATION = `${ROTATE_KEYFRAME} ${ROTATE_TRANSITION_DURATION} ${ROTATE_TRANSITION_TIMING_FUNCTION} infinite`;

export const SHIMMER_KEYFRAME = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const SHIMMER_TRANSITION_DURATION = "2s";
export const SHIMMER_TRANSITION_TIMING_FUNCTION = "linear";

export const SEARCH_BAR_APPEARANCE = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
