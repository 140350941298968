import React, { useContext, useState } from "react";

const ButtonLoadingContext = React.createContext<Context>({
  isLoading: false,
  setIsLoading: () => undefined,
});

type Context = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

type Props = {
  children: React.ReactNode;
};

// this context, used with the generic button component, dispatches to loading state of that Button component
// for other components within the same provider to be disabled.
export const ButtonLoadingProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ButtonLoadingContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </ButtonLoadingContext.Provider>
  );
};

export default ButtonLoadingContext;

export const useButtonLoading = () => useContext(ButtonLoadingContext);
