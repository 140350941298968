import { createSlice } from "@reduxjs/toolkit";

import { Role } from "apis/orgRoles";
import { OrgMembers } from "apis/orgs";

import { Action, State } from "./store";

export const orgMemberSlice = createSlice({
  name: "orgMembers",
  initialState: {
    value: {} as OrgMembers,
  },
  reducers: {
    updateOrgMembers: (state, action: Action<OrgMembers>) => {
      Object.entries(action.payload).forEach(([org, members]) => {
        state.value[org] = members;
      });
    },

    removeActiveOrgMember: (
      state,
      action: Action<{
        orgId: string;
        userId: string;
      }>
    ) => {
      const { orgId, userId } = action.payload;
      if (!state.value[orgId]) return;
      state.value[orgId] = state.value[orgId].filter(
        (member) => member.id !== userId
      );
    },

    updateSingleOrgMemberRoles: (
      state,
      action: Action<{ orgId: string; userId: string; roles: Role[] }>
    ) => {
      const { orgId, userId, roles } = action.payload;
      if (!state.value[orgId]) return;

      state.value[orgId] = state.value[orgId].map((m) =>
        m.id === userId ? { ...m, roles } : m
      );
    },
  },
});

export const {
  updateOrgMembers,
  removeActiveOrgMember,
  updateSingleOrgMemberRoles,
} = orgMemberSlice.actions;

export const selectActiveOrgMembers = (state: State) => state.orgMembers.value;

export const selectActiveOrgMembersInOrg =
  (orgId?: string) => (state: State) => {
    if (!orgId) return [];
    return state.orgMembers.value[orgId];
  };

export default orgMemberSlice.reducer;
