import { createSlice } from "@reduxjs/toolkit";

import { Document } from "apis/document";

import { Action, State } from "./store";

export const documents = createSlice({
  name: "documents",
  initialState: {
    value: undefined as Document[] | undefined,
  },
  reducers: {
    addDocuments: (state, action: Action<Document[]>) => {
      state.value = action.payload;
    },
    addDocument: (state, action: Action<Document>) => {
      state.value = [action.payload, ...(state.value || [])];
    },
    updateDocument: (state, action: Action<Document>) => {
      const updatedDoc = action.payload;
      state.value = (state.value || []).map((doc) =>
        doc.filename === updatedDoc.filename ? updatedDoc : doc
      );
    },
    deleteDocument: (state, action: Action<string>) => {
      const deletedFilename = action.payload;
      state.value = (state.value || []).filter(
        (doc) => doc.filename !== deletedFilename
      );
    },
  },
});

export const { addDocuments, addDocument, updateDocument, deleteDocument } =
  documents.actions;

export const selectDocuments = (state: State) => {
  return state.documents.value;
};

export default documents.reducer;
