import { useEffect, useState } from "react";

export const isRibbonExtensionInstalled = async () => {
  if (typeof chrome !== "undefined" && chrome.runtime?.sendMessage) {
    return new Promise((resolve) => {
      chrome.runtime.sendMessage(
        "djpciihncdncnahnahjgmjpjcioakgan",
        { type: "isInstalled" },
        (response) => {
          if (!response.success) {
            return resolve(false);
          }
          return resolve(true);
        }
      );
    });
  }

  return false;
};

type IsInstalledLoaded = { isInstalled: boolean; isLoaded: true };
type IsInstalledLoading = { isInstalled: undefined; isLoaded: false };
export const useIsCXInstalled = (): IsInstalledLoaded | IsInstalledLoading => {
  const [isInstalled, setIsInstalled] = useState<boolean>();

  useEffect(() => {
    isRibbonExtensionInstalled().then((isInstalled) => {
      setIsInstalled(!!isInstalled);
    });
  }, []);

  if (typeof isInstalled === "undefined") {
    return { isInstalled: undefined, isLoaded: false };
  } else {
    return { isInstalled, isLoaded: true };
  }
};

export const passAuthTokensToExtension = ({
  refreshToken,
  accessToken,
}: {
  refreshToken?: string;
  accessToken?: string;
}) => {
  if (typeof chrome !== "undefined" && chrome.runtime?.sendMessage) {
    chrome.runtime.sendMessage(
      "djpciihncdncnahnahjgmjpjcioakgan",
      { refreshToken, accessToken, type: "auth" },
      (response) => {
        if (!response.success) {
          return response;
        }
      }
    );
  }
};
