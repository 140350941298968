import { createSlice } from "@reduxjs/toolkit";

import { AccountProfile } from "apis/accountProfile";
import { Org } from "apis/orgs";

import { Action, State } from "./store";

export const accountProfileSlice = createSlice({
  name: "accountProfile",
  initialState: {
    value: {} as AccountProfile | undefined,
  },
  reducers: {
    updateAccountProfile: (
      state,
      action: Action<AccountProfile | undefined>
    ) => {
      state.value = action.payload;
    },
    updateActiveOrgId: (state, action: Action<string>) => {
      if (state.value && Object.keys(state.value).length) {
        state.value.activeOrgId = action.payload;
      }
    },
    updateOrg: (
      state,
      action: Action<Pick<Org, "id" | "name" | "photoUrl" | "isVerified">>
    ) => {
      const { id, ...updatedData } = action.payload;
      if (state.value) {
        state.value.orgs = state.value.orgs.map((org) =>
          org.id === id ? { ...org, ...updatedData } : org
        );
      }
    },
  },
});

export const { updateAccountProfile, updateActiveOrgId, updateOrg } =
  accountProfileSlice.actions;

export const selectAccountProfile = (state: State) => {
  if (
    !state.accountProfile.value ||
    !Object.keys(state.accountProfile.value).length
  )
    return undefined;
  return {
    ...state.accountProfile.value,
    activeOrg: state.accountProfile.value.orgs?.find(
      (org) => org.id === state.accountProfile.value.activeOrgId
    ),
  };
};

export default accountProfileSlice.reducer;
