import React, { useContext } from "react";

type Context = {
  isAuthGated: boolean;
};

export const AuthGatedContext = React.createContext<Context>({
  isAuthGated: false,
} as Context);

type Props = {
  children: React.ReactNode;
};
export const AuthGatedProvider = ({ children }: Props) => {
  return (
    <AuthGatedContext.Provider value={{ isAuthGated: true }}>
      {children}
    </AuthGatedContext.Provider>
  );
};

export const useIsAuthGated = () => {
  return useContext(AuthGatedContext);
};
