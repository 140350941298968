import React, { useContext, useEffect, useState } from "react";

import * as Sentry from "@sentry/nextjs";
import { Subscription, User } from "@supabase/supabase-js";
import { identifyUser } from "interfaces/tracker";

import { getSession, onAuthStateChange } from "apis/authentication";
import { createReferral } from "apis/referral";

import { addToABBuckets } from "utils/ABTesting";
import { passAuthTokensToExtension } from "utils/chromeExtension";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "utils/localStorage";

import { addAuthTokensToCookies } from "../apis/supabaseClient";

type Context = { user: User | undefined; isLoaded: boolean };

export const AuthContext = React.createContext<Context>({
  user: undefined,
  isLoaded: false,
});

type Props = {
  children: React.ReactNode;
};
export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | undefined>();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const initiateSession = async () => {
      const session = await getSession();

      setUser(session?.user);
      setIsLoaded(true);

      if (session?.user.id) {
        Sentry.setUser({ id: session.user.id });
        addToABBuckets();
      }

      const refreshToken = session?.refresh_token;
      const accessToken = session?.access_token;
      passAuthTokensToExtension({ refreshToken, accessToken });
    };

    initiateSession();
  }, []);

  useEffect(() => {
    let authStateChangeListener: Subscription | null = null;

    authStateChangeListener = onAuthStateChange((event, session) => {
      setUser(session?.user);
      addAuthTokensToCookies(event, session);
      let refreshToken;
      let accessToken;
      if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED") {
        refreshToken = session?.refresh_token;
        accessToken = session?.access_token;
      }
      passAuthTokensToExtension({ refreshToken, accessToken });
    });

    return () => {
      authStateChangeListener?.unsubscribe();
    };
  }, []);

  // log referrals if referrerUserId is in local storage
  // we can't rely on an event listener for local storage
  // so we check on every render
  useEffect(() => {
    const referrerUserId = getFromLocalStorage("referrerUserId");
    const doReferral = async () => {
      if (referrerUserId && user?.id) {
        const { error } = await createReferral(referrerUserId, user.id);

        if (!error) deleteFromLocalStorage("referrerUserId");
      }
    };

    doReferral();
  });

  // identify user for mixpanel
  useEffect(() => {
    if (user?.id) {
      identifyUser(user.id);
    }
  }, [user?.id]);

  const value = { user, isLoaded };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
