import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { standardWaitTime } from "constants/time";

import { isViewMobile } from "./mediaQueryStrings";

// this hook can be used in any element to handle the logic of triggering a state update when clicking outside of an element
// see src/components/shared/library/MoreActionButton.tsx as an example
export const useCloseOnClickOutside = (
  isVisible: boolean,
  setIsVisible: (isVisible: boolean) => void,
  nodeId: string, // this is the id string used on the node that should not trigger closing when clicked on
  options?: { exceptSelectors?: string[] }, // a list of selectors where when clicked on, they should not trigger a closing
  stopPropagation = true
) => {
  useEffect(() => {
    // define  a method to remove the listener
    let removeClickListener: () => void = () => undefined;
    if (isVisible) {
      // this function define what happens when clicking anywhere but in the menu
      const onClickOutside = (e: Event) => {
        // checking if the clicked element is not the menu or a child or the menu
        if (
          (e.target as Element).closest(`#${nodeId}`) === null &&
          (!options?.exceptSelectors?.length ||
            options?.exceptSelectors?.every(
              (selector) => (e.target as Element).closest(selector) === null
            ))
        ) {
          if (stopPropagation) e.stopPropagation();
          setIsVisible(false);
          removeClickListener();
        }
      };
      // if the menu is visible, we listen to all clicks in the document in order to trigger onClickOutside
      // which closes the menu and removes the listener if the click was outside the menu
      document.addEventListener("click", onClickOutside, {
        capture: true,
      });

      // this function removes the listener
      removeClickListener = () => {
        document.removeEventListener("click", onClickOutside, {
          capture: true,
        });
      };
    } else {
      // if the element is no longer visible we should remove the listener
      removeClickListener();
    }
    // when unmounting we should remove the listener
    return () => removeClickListener();
  }, [isVisible, nodeId, setIsVisible, options, stopPropagation]);
};

export const useMaxChar = () => {
  const [maxCharExceeded, setMaxCharExceeded] = useState(false);

  useEffect(() => {
    if (maxCharExceeded) {
      setTimeout(() => setMaxCharExceeded(false), standardWaitTime);
    }
  }, [maxCharExceeded]);

  return [maxCharExceeded, setMaxCharExceeded] as [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ];
};

export const useIsMobileViewPort = () => {
  // when mounting the component from the server, we cannot know what viewport will be
  // we would return SSR to let the consumer components handle the logic in that case
  const [isMobileViewport, setIsMobileViewport] = useState<boolean | "SSR">(
    "SSR"
  );

  const onResize = useCallback(() => setIsMobileViewport(isViewMobile()), []);

  useEffect(() => {
    // Run onResize in the first CSR load
    onResize();

    // we listen to window resizes
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return isMobileViewport;
};

type SetScrollTo = (id: string | undefined) => void;

export const useSmoothScroll = (containerId?: string): SetScrollTo => {
  const [scrollTo, setScrollTo] = useState<string | undefined>();

  useEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(scrollTo);

      if (element && containerId) {
        const scrollArea = document.getElementById(containerId);
        if (scrollArea) {
          scrollArea.scrollTo({
            top: element.offsetTop,
            behavior: "smooth",
          });
        }
      } else if (element && element.scrollIntoView) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }

      setScrollTo(undefined);
    }
  }, [scrollTo, containerId]);

  return setScrollTo;
};
