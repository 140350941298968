import Head from "next/head";

type Props = {
  title: string;
  description: string;
  url: string;
};

export const OpenGraph = ({ title, description, url }: Props) => {
  return (
    <Head>
      {/* general open graph stuff */}
      <meta property="og:title" content={title} key="og:title" />
      <meta property="og:type" content="website" key="og:type" />
      <meta
        property="og:image"
        content="/images/ribbon-og.png"
        key="og:image"
      />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      {/* twitter open graph stuff */}
      <meta name="twitter:card" content="summary" key="twitter:card" />
      <meta name="twitter:site" content="@RibbonAward" key="twitter:site" />
      <meta property="twitter:domain" content={url} key="twitter:domain" />
      <meta name="twitter:title" content={title} key="twitter:title" />
      <meta
        name="twitter:description"
        content={description}
        key="twitter:description"
      />
      <meta
        name="twitter:image"
        content="https://app.ribbon.ai/images/ribbon-og.png"
        key="twitter:image"
      />
    </Head>
  );
};
