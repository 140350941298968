import { createSlice } from "@reduxjs/toolkit";

import { Post } from "apis/posts";
import { UserInfo } from "apis/user";

import { Action, State } from "./store";

export const postsSlice = createSlice({
  name: "posts",
  initialState: {
    value: [] as Post[],
  },
  reducers: {
    addPosts: (state, action: Action<Post[]>) => {
      state.value = action.payload;
    },
    appendPosts: (state, action: Action<Post[]>) => {
      state.value = [...state.value, ...action.payload];
    },
    updatePost: (state, action: Action<Post>) => {
      const changedIdx = state.value.findIndex(
        (post) => post.id === action.payload.id
      );
      state.value[changedIdx] = action.payload;
    },
    deletePost: (state, action: Action<{ id: string }>) => {
      state.value = state.value.filter(({ id }) => id !== action.payload.id);
    },
    clearPosts: (state) => {
      state.value = [];
    },
    likePost: (state, action: Action<{ id: string }>) => {
      const changedIdx = state.value.findIndex(
        (post) => post.id === action.payload.id
      );
      state.value[changedIdx].isLikedByAuthedUser = true;
      state.value[changedIdx].likeCount++;
    },
    unLikePost: (state, action: Action<{ id: string }>) => {
      const changedIdx = state.value.findIndex(
        (post) => post.id === action.payload.id
      );
      state.value[changedIdx].isLikedByAuthedUser = false;
      state.value[changedIdx].likeCount--;
    },
    likePostComment: (
      state,
      action: Action<{
        postId: string;
        postCommentId: string;
        parentCommentId?: string;
      }>
    ) => {
      const changedIdx = state.value.findIndex(
        (post) => post.id === action.payload.postId
      );

      const comments = state.value[changedIdx].comments;

      if (action.payload.parentCommentId) {
        // If the comment has a parent, it's a child comment
        const parentCommentIdx = comments.findIndex(
          (comment) => comment.id === action.payload.parentCommentId
        );

        const childComments = comments[parentCommentIdx].replies;

        if (!childComments) return;

        const childCommentIdx = childComments.findIndex(
          (comment) => comment.id === action.payload.postCommentId
        );

        childComments[childCommentIdx].isLikedByAuthedUser = true;
        childComments[childCommentIdx].likeCount++;
      } else {
        // If the comment doesn't have a parent, it's a top-level comment
        const commentIdx = comments.findIndex(
          (comment) => comment.id === action.payload.postCommentId
        );

        comments[commentIdx].isLikedByAuthedUser = true;
        comments[commentIdx].likeCount++;
      }
    },
    unLikePostComment: (
      state,
      action: Action<{
        postId: string;
        postCommentId: string;
        parentCommentId?: string;
      }>
    ) => {
      const changedIdx = state.value.findIndex(
        (post) => post.id === action.payload.postId
      );

      const comments = state.value[changedIdx].comments;

      if (action.payload.parentCommentId) {
        // If the comment has a parent, it's a child comment
        const parentCommentIdx = comments.findIndex(
          (comment) => comment.id === action.payload.parentCommentId
        );

        const childComments = comments[parentCommentIdx].replies;

        if (!childComments) return;

        const childCommentIdx = childComments.findIndex(
          (comment) => comment.id === action.payload.postCommentId
        );

        childComments[childCommentIdx].isLikedByAuthedUser = false;
        childComments[childCommentIdx].likeCount--;
      } else {
        // If the comment doesn't have a parent, it's a top-level comment
        const commentIdx = comments.findIndex(
          (comment) => comment.id === action.payload.postCommentId
        );

        comments[commentIdx].isLikedByAuthedUser = false;
        comments[commentIdx].likeCount--;
      }
    },
    createPostComment: (
      state,
      {
        payload: { postId, parentCommentId, text, author, tempId },
      }: Action<{
        postId: string;
        parentCommentId?: string;
        text: string;
        author: Pick<
          UserInfo,
          | "id"
          | "playbackId"
          | "avatarUrl"
          | "color"
          | "fullName"
          | "shape"
          | "handle"
        >;
        tempId: string;
      }>
    ) => {
      const changedIdx = state.value.findIndex((post) => post.id === postId);

      const newComment = {
        id: tempId,
        text: text,
        author: author,
        createdAt: new Date().toISOString(),
        isLikedByAuthedUser: false,
        likeCount: 0,
      };

      const comments = state.value[changedIdx].comments;

      if (parentCommentId) {
        const parentCommentIdx = comments.findIndex(
          (comment) => comment.id === parentCommentId
        );
        if (parentCommentIdx !== -1) {
          if (!comments[parentCommentIdx].replies) {
            comments[parentCommentIdx].replies = [];
          }

          comments[parentCommentIdx]?.replies?.push(newComment);
        }
      } else {
        comments.push(newComment);
      }
    },

    deletePostComment: (
      state,
      {
        payload: { postId, parentCommentId, postCommentId },
      }: Action<{
        postId: string;
        parentCommentId?: string;
        postCommentId: string;
      }>
    ) => {
      const changedIdx = state.value.findIndex((post) => post.id === postId);

      const comments = state.value[changedIdx].comments;

      if (parentCommentId) {
        const parentCommentIdx = comments.findIndex(
          (comment) => comment.id === parentCommentId
        );
        if (parentCommentIdx !== -1) {
          comments[parentCommentIdx].replies = comments[
            parentCommentIdx
          ].replies?.filter((comment) => comment.id !== postCommentId);
        }
      } else {
        state.value[changedIdx].comments = state.value[
          changedIdx
        ].comments.filter((comment) => comment.id !== postCommentId);
      }
    },
  },
});

export const {
  addPosts,
  updatePost,
  deletePost,
  clearPosts,
  appendPosts,
  likePost,
  unLikePost,
  likePostComment,
  unLikePostComment,
  createPostComment,
  deletePostComment,
} = postsSlice.actions;

export const selectPosts = (state: State): Post[] => {
  return state.posts.value;
};

export default postsSlice.reducer;
