import { useEffect, useState } from "react";

import { logger } from "interfaces/logger";

import { supabase } from "apis/supabaseClient";

import { realtimeHealthCheckInterval } from "constants/time";

export const useRealtimeChannelHealth = (
  channelName: string,
  isChannelExpected = true
) => {
  // assume channel is healthy until proven otherwise
  const [isHealthy, setIsHealthy] = useState(true);
  const [numUnhealthyStatuses, setNumUnhealthyStatuses] = useState(0);

  useEffect(() => {
    if (!isChannelExpected) return;
    const checkHealth = async () => {
      // unsub to all channels not currently joined
      const existingChannels = supabase.getChannels();

      const potentialChannel = existingChannels.find(
        (existingChannel) => existingChannel.topic === `realtime:${channelName}`
      );

      const isChannelHealthy =
        !!potentialChannel && potentialChannel.state === "joined";

      setIsHealthy(isChannelHealthy);

      if (!isChannelHealthy) {
        setNumUnhealthyStatuses((prev) => prev + 1);
      }

      if (isChannelHealthy) {
        setNumUnhealthyStatuses(0);
      }
    };

    // check now and at regular interval
    checkHealth();
    const intervalId = setInterval(checkHealth, realtimeHealthCheckInterval);

    return () => clearInterval(intervalId);
  }, [channelName, isChannelExpected]);

  useEffect(() => {
    // only log the error once
    if (numUnhealthyStatuses == 3) {
      logger(`Channel ${channelName} is not healthy after 3 tries`, "error");
    }

    if (numUnhealthyStatuses > 3) {
      logger(`Channel ${channelName} is not healthy after 3 tries`, "info");
    }
  }, [channelName, numUnhealthyStatuses]);

  return isChannelExpected ? isHealthy : true;
};
