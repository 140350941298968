import { IconProps } from "Icons/types";

export const RibbonPeelIcon = ({
  width = 56,
  height = 57,
  fill = "#82ACFF",
  stroke = "#4791FF",
  strokeWidth = 2.27027,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="RibbonPeelIcon"
  >
    <g filter="url(#filter0_dd_2227_536288)">
      <path
        d="M52.3257 24.3724C54.3293 37.807 45.0627 50.3222 31.6281 52.3259C30.0938 52.5547 28.5715 52.6365 27.0755 52.5819C20.0857 50.6042 14.3161 47.8304 9.30564 43.9869C6.39975 40.5953 4.38202 36.3716 3.67459 31.6283C1.67092 18.1937 10.9375 5.67844 24.3722 3.67477C37.8068 1.67111 50.322 10.9377 52.3257 24.3724Z"
        fill={fill}
      />
      <path
        d="M51.203 24.5398C53.1141 37.3544 44.2752 49.292 31.4607 51.2031C30.0421 51.4147 28.6353 51.4945 27.2522 51.4521C20.4817 49.5222 14.9167 46.8398 10.0912 43.1586C7.36118 39.9398 5.46611 35.9452 4.79731 31.4608C2.88612 18.6463 11.7251 6.70868 24.5396 4.79749C37.3542 2.8863 49.2918 11.7252 51.203 24.5398Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </g>
    <path
      d="M41.9793 22.8042C42.5727 26.7824 40.403 30.5245 36.9197 32.1483C38.0339 32.438 39.2259 32.5127 40.4405 32.3316C41.616 32.1563 42.7113 32.9661 42.8883 34.1413L43.1701 36.0131C43.3477 37.1921 42.5349 38.2915 41.3556 38.4674C36.5855 39.1788 32.007 37.5792 28.7806 34.5149C26.5887 38.3873 22.6763 41.2532 17.9061 41.9647C16.6957 42.1452 15.5686 41.309 15.3903 40.0983L15.1309 38.3363C14.9533 37.1305 15.7856 36.0087 16.991 35.8289C18.2056 35.6477 19.324 35.2285 20.3052 34.6262C16.4997 34.0895 13.3325 31.1434 12.7392 27.1652C12.0147 22.3077 15.4095 17.8023 20.2851 17.0752C22.4866 16.7468 24.6212 17.2434 26.3668 18.3301C27.7193 16.7814 29.6161 15.6835 31.8176 15.3552C36.6932 14.628 41.2549 17.9468 41.9793 22.8042ZM30.4468 24.5242C30.6616 25.9646 32.0247 26.9812 33.5183 26.7584C35.012 26.5356 36.0191 25.1656 35.8043 23.7252C35.5894 22.2848 34.2264 21.2682 32.7327 21.491C31.2391 21.7137 30.232 23.0838 30.4468 24.5242ZM21.9858 28.4784C23.4794 28.2556 24.4865 26.8856 24.2717 25.4452C24.0569 24.0048 22.6938 22.9882 21.2002 23.211C19.7065 23.4337 18.6994 24.8038 18.9143 26.2442C19.1291 27.6846 20.4921 28.7012 21.9858 28.4784Z"
      fill="white"
      stroke={stroke}
      strokeWidth="2.69873"
    />
    <path
      d="M10.5547 43.1465C15.8997 47.105 20.4774 49.3322 26.1979 51.0112C26.2902 50.3527 26.27 49.7436 26.1408 48.8772C25.3731 43.7297 20.6307 40.1932 15.5707 40.9479C13.5743 41.2456 11.858 41.977 10.5547 43.1465Z"
      fill="white"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
    <defs>
      <filter
        id="filter0_dd_2227_536288"
        x="1.13183"
        y="2.64559"
        width="53.7366"
        height="53.7368"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="0.756757"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_2227_536288"
        />
        <feOffset dy="0.756757" />
        <feGaussianBlur stdDeviation="0.756757" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2227_536288"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="0.756757"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_2227_536288"
        />
        <feOffset dy="1.51351" />
        <feGaussianBlur stdDeviation="1.51351" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2227_536288"
          result="effect2_dropShadow_2227_536288"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2227_536288"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
