import React from "react";

export const getInnerText = (children: React.ReactNode): string => {
  if (typeof children === "string") {
    return children;
  }
  if (Array.isArray(children)) {
    return children.map(getInnerText).join("");
  }
  if (React.isValidElement(children)) {
    return getInnerText(children.props.children);
  }
  return "";
};

export const truncateStringAtTarget = (
  string: string,
  target: string
): string => {
  if (!target) return string;
  const targetIndex = string.indexOf(target);
  if (targetIndex > 0) {
    const start = string.substring(targetIndex);
    return "..." + start;
  } else {
    return string; // Return the original bio if the target is not found in the bio
  }
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// format string like "+12136686541" to "+1 (213) 668-6541"
export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber.match(/^\+\d{11}$/)) {
    return "Invalid phone number format";
  }
  const match = phoneNumber.match(/^\+(\d)(\d{3})(\d{3})(\d{4})$/);

  // Format the number into the desired format
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  } else {
    return "Invalid phone number format";
  }
};

// format string like "12136686541" to "1213***6541"
export const partiallyHideApiKey = (apiKey: string) => {
  const totalLength = apiKey.length;
  const revealedCharStart = 4;
  const revealedCharEnd = 4;
  const start = apiKey.slice(0, revealedCharStart);
  const end = apiKey.slice(totalLength - revealedCharEnd, totalLength);
  const hidden = "*".repeat(
    apiKey.length - revealedCharStart - revealedCharEnd
  );
  return `${start}${hidden}${end}`;
};
