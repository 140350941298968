export const addToLocalStorage = (
  key: string,
  value: string | string[] | number[] | number
) => {
  if (typeof window === "undefined") return;
  if (typeof value !== "string") {
    localStorage.setItem(`ribbon-${key}`, JSON.stringify(value));
  } else {
    localStorage.setItem(`ribbon-${key}`, value);
  }
};

export const getFromLocalStorage = (key: string) => {
  if (typeof window === "undefined") return null;
  const data = localStorage.getItem(`ribbon-${key}`);
  return data;
};

export const deleteFromLocalStorage = (key: string) => {
  if (typeof window === "undefined") return;
  localStorage.removeItem(`ribbon-${key}`);
};
