import React, { createContext, useState, useEffect, useContext } from "react";

import { useRouter } from "next/router";

type Context = {
  history: string[];
};

const HistoryContext = createContext<Context>({
  history: [],
});
type Props = {
  children: React.ReactNode;
};
export const HistoryProvider = ({ children }: Props) => {
  const { asPath } = useRouter();
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    setHistory((previous) => [...previous, asPath]);
  }, [asPath]);

  return (
    <HistoryContext.Provider
      value={{
        history,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistory = () => useContext(HistoryContext);
