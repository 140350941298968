import { keyBy } from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import { OrgRolesAndLimits } from "apis/orgs";

import { Action, State } from "./store";

export const orgRolesAndLimitsSlice = createSlice({
  name: "orgRolesAndLimits",
  initialState: {
    value: {} as Record<string, OrgRolesAndLimits>,
  },
  reducers: {
    updateOrgRolesAndLimits: (state, action: Action<any>) => {
      state.value = keyBy(action.payload, "orgId");
    },
  },
});

export const { updateOrgRolesAndLimits } = orgRolesAndLimitsSlice.actions;

export const selectOrgRolesAndLimits = (state: State) =>
  state.orgRolesAndLimits.value;

export default orgRolesAndLimitsSlice.reducer;
