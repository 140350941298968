import { isThisMonth } from "date-fns";

import { PostgrestError } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";

import { supabase } from "apis/supabaseClient";

export const createReferral = async (
  referrerId: string,
  referredId: string
): Promise<{ error: PostgrestError | null | string }> => {
  // don't record self-referrals
  if (referrerId === referredId) {
    return { error: null };
  }

  const { data, error } = await supabase
    .from("referral")
    .select("*")
    .eq("referring_user_id", referrerId);

  const filteredData = data?.filter(
    (entry) => entry.referred_user_id === referredId
  );

  if (filteredData && filteredData.length == 0) {
    const { error } = await supabase
      .from("referral")
      .insert({ referring_user_id: referrerId, referred_user_id: referredId });

    return { error };
  }

  return { error };
};

export const fetchTotalReferrals = async (
  referrerId: string
): Promise<{ numReferrals: number }> => {
  const { data, error } = await supabase.rpc("get_distinct_referrals", {
    user_id: referrerId,
  });

  if (error) {
    logger(error.message, "error");
  }

  const numReferrals = data ? data.length : 0;

  return { numReferrals: numReferrals };
};

export const fetchCurrentMonthsReferrals = async (
  referrerId: string
): Promise<{ numReferrals: number }> => {
  const { data, error } = await supabase.rpc("get_distinct_referrals", {
    user_id: referrerId,
  });

  if (error) {
    logger(error.message, "error");
  }

  const thisMonthsReferrals = data?.filter((d: { created_at: string }) =>
    isThisMonth(new Date(d.created_at))
  );

  const numReferrals = thisMonthsReferrals ? thisMonthsReferrals.length : 0;

  return { numReferrals: numReferrals };
};
