import { PropsWithChildren } from "react";

import NextHead from "next/head";

type Props = {
  pageTitle?: string;
};

export const Head = ({ pageTitle }: PropsWithChildren<Props>) => {
  return (
    <NextHead>
      <title>{pageTitle ? `${pageTitle} - Ribbon` : "Ribbon"}</title>
      <meta name="ribbon" content={pageTitle} />
      <link rel="shortcut icon" href="/images/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/images/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/images/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/images/favicon-16x16.png"
      />
    </NextHead>
  );
};
