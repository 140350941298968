import { RibbonLogoIcon } from "Icons/RibbonLogo";

import Link from "next/link";

import { Image } from "components/shared/library/Image";

import { getHomeUrl } from "utils/urls";

type RibbonLogoProps = {
  isLink?: boolean;
  width?: number;
  height?: number;
  showLogoOnly?: boolean;
};

export const RibbonLogo = ({
  isLink,
  width = 108,
  height = 24,
  showLogoOnly = false,
}: RibbonLogoProps) => {
  return (
    <>
      {isLink ? (
        <Link href={getHomeUrl()}>
          {showLogoOnly ? (
            <RibbonLogoIcon />
          ) : (
            <Image
              src="/images/Black.png"
              alt="Ribbon logo"
              width={width}
              height={height}
            />
          )}
        </Link>
      ) : (
        <>
          {showLogoOnly ? (
            <RibbonLogoIcon />
          ) : (
            <Image
              src="/images/Black.png"
              alt="Ribbon logo"
              width={width}
              height={height}
            />
          )}
        </>
      )}
    </>
  );
};

export default RibbonLogo;
