import { breakpoints } from "constants/viewports";

// these queries have been implemented to mimic material UI CSS media query:https://mui.com/material-ui/customization/breakpoints/#css-media-queries
// additionally, there are 3 more queries: only, not and between, that we should implement when needed.

// A media query string which matches screen widths greater than the screen size given by the breakpoint key (inclusive).
export const up = (breakpointKey: keyof typeof breakpoints) =>
  `@media screen and (min-width: ${breakpoints[breakpointKey]}px)`;

// A media query string which matches screen widths less than the screen size given by the breakpoint key (exclusive).
export const down = (breakpointKey: keyof typeof breakpoints) =>
  `@media screen and (max-width: ${breakpoints[breakpointKey] - 1}px)`;

// A container query string which matches container widths less than the size given by the breakpoint key (exclusive).
export const containerDown = (breakpointKey: keyof typeof breakpoints) =>
  `@container (max-width: ${breakpoints[breakpointKey] - 1}px)`;

export const isViewMobile = () =>
  typeof window !== "undefined" && window.innerWidth <= breakpoints.mobile;
