import * as Sentry from "@sentry/nextjs";

export const logger = (message: string, logType?: "error" | "info"): void => {
  switch (logType) {
    case "error":
      console.error(message);
      Sentry.captureException(new Error(message));
      break;
    case "info":
    default:
      console.log(message);
  }
};
