import { trackEvent } from "interfaces/tracker";

export const submitFeedback = async ({
  rating,
  userId,
  description,
  interviewId,
}: {
  rating: number;
  userId?: string;
  description?: string;
  interviewId?: string;
}): Promise<{ error: string | null }> => {
  const res = await fetch("/be-api/submit-feedback", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      rating,
      userId,
      description,
      interviewId,
    }),
  });

  trackEvent("FeedbackRating", {
    rating: rating,
    userId: userId,
    description: description,
  });

  return {
    error: res.status !== 200 ? "Something unexpected happened" : null,
  };
};
