import { createSlice } from "@reduxjs/toolkit";

import { cloneDeep } from "lodash";

import { Notification } from "apis/notifications";

import { Action, State } from "./store";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    value: [] as Notification[],
    isLoaded: false,
  },
  reducers: {
    addNotifications: (state, action: Action<Notification[]>) => {
      state.value = action.payload;
      state.isLoaded = true;
    },
    addNotification: (state, action: Action<Notification>) => {
      if (action.payload.actionRequired) {
        // if action required, add first
        state.value = [action.payload, ...state.value];
      } else {
        // else add after the action required items
        const idxOfFirstAlert = state.value.findIndex(
          (notif) => !notif.actionRequired
        );
        if (idxOfFirstAlert === -1)
          state.value = [action.payload, ...state.value];

        const newState = cloneDeep(state.value);
        newState.splice(idxOfFirstAlert, 0, action.payload);
        state.value = newState;

        state.isLoaded = true;
      }
    },
    removeNotification: (state, action: Action<string>) => {
      state.value = state.value.filter((notif) => notif.id !== action.payload);
    },
    markAsViewed: (state, action: Action<string[]>) => {
      const now = new Date().toISOString();
      const viewedNotifIds = new Set(action.payload);
      state.value = state.value.map((notif) =>
        viewedNotifIds.has(notif.id) ? { ...notif, viewedAt: now } : notif
      );
    },
    markAsActionTaken: (state, action: Action<string[]>) => {
      const now = new Date().toISOString();
      const viewedNotifIds = new Set(action.payload);
      state.value = state.value.map((notif) =>
        viewedNotifIds.has(notif.id)
          ? { ...notif, viewedAt: now, actionRequired: false }
          : notif
      );
    },
  },
});

export const {
  addNotifications,
  addNotification,
  removeNotification,
  markAsViewed,
  markAsActionTaken,
} = notificationsSlice.actions;

export const selectNewNotifsCount = (state: State): number | undefined =>
  state.notifications.value.filter(({ viewedAt }) => !viewedAt).length;

export const selectNotifications = (state: State): Notification[] =>
  state.notifications.value;

export const selectIsNotificationsLoaded = (state: State): boolean =>
  state.notifications.isLoaded;

export default notificationsSlice.reducer;
