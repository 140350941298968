import { useEffect, useState } from "react";

import { PostgrestResponse } from "@supabase/postgrest-js";
import { PostgrestError, User } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";

import { FrameShape } from "components/shared/library/Avatar";

import { supabase } from "apis/supabaseClient";

import { addToLocalStorage, getFromLocalStorage } from "utils/localStorage";
import { useRealtimeChannelHealth } from "utils/realtime";

import { fetchAuthWrapper } from "./backend";
import { Role } from "./orgRoles";
import { ProfileColor } from "./posts";

export type UserInfo = {
  id: string;
  avatarUrl?: string;
  color: ProfileColor;
  fullName: string;
  firstName: string;
  lastName: string;
  shape: FrameShape;
  headline: string;
  handle: string;
  activeOrgId?: string;
  email: string;
  playbackId?: string | null;
  isVerified?: boolean;
};

export type UserPreview = Pick<
  UserInfo,
  | "id"
  | "color"
  | "playbackId"
  | "avatarUrl"
  | "fullName"
  | "shape"
  | "handle"
  | "isVerified"
>;

export type UserDetail = Omit<UserInfo, "handle"> & {
  roles: Role[];
};

type DbUserDetail = {
  id: string;
  avatar_url: string;
  shape: string;
  color: string;
  full_name: string;
  first_name: string;
  last_name: string;
  email: string;
  headline: string;
  active_org_id: string;
  roles: Role[];
};

export const getUserDetails = async (
  userId: string,
  orgId: string
): Promise<UserDetail> => {
  const { data, error }: PostgrestResponse<DbUserDetail> = await supabase.rpc(
    "get_user_details",
    {
      user_id: userId,
      org_id: orgId,
    }
  );

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  const userDetail: UserDetail = {
    id: data[0].id,
    avatarUrl: data[0].avatar_url,
    color: data[0].color as ProfileColor,
    fullName: data[0].full_name,
    firstName: data[0].first_name,
    lastName: data[0].last_name,
    shape: data[0].shape as FrameShape,
    headline: data[0].headline,
    activeOrgId: data[0].active_org_id,
    email: data[0].email,
    roles: data[0].roles,
  };

  return userDetail;
};

type SignupStatus = "complete" | "addressAlreadyInUse" | "inProgress";

const getUserSignupStatus = async (userId: string): Promise<SignupStatus> => {
  const { data, error } = await supabase
    .from("user_public_profiles")
    .select("signup_status")
    .eq("id", userId);

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  if (!data.length) return "inProgress";
  return {
    complete: "complete" as SignupStatus,
    address_already_in_use: "addressAlreadyInUse" as SignupStatus,
    in_progress: "inProgress" as SignupStatus,
  }[
    data[0].signup_status as unknown as
      | "complete"
      | "address_already_in_use"
      | "in_progress"
  ];
};

export const useUserSignupStatusRealtime = (
  user: User | undefined
): { signupStatus: SignupStatus; isLoaded: boolean } => {
  const [signupStatus, setSignupStatus] = useState<SignupStatus>("inProgress");
  const [isLoaded, setIsLoaded] = useState(false);

  const userSignupStatusChannelName = "db-user-signup-status";
  const isChannelHealthy = useRealtimeChannelHealth(
    userSignupStatusChannelName,
    !!user
  );
  useEffect(() => {
    const initialSignupStatus = getFromLocalStorage("signup-status");
    if (initialSignupStatus) {
      setSignupStatus(initialSignupStatus as SignupStatus);
      setIsLoaded(initialSignupStatus !== "inProgress");
    }
  }, []);

  useEffect(() => {
    addToLocalStorage("signup-status", signupStatus);
  }, [signupStatus]);

  useEffect(() => {
    if (!user || (isLoaded && isChannelHealthy)) return;

    const channel = supabase.channel(userSignupStatusChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "user_public_profiles",
        filter: `id=eq.${user.id}`,
      },
      async () => {
        const userSignupStatus = await getUserSignupStatus(user.id);
        setSignupStatus(userSignupStatus);
      }
    );

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "user_public_profiles",
        filter: `id=eq.${user.id}`,
      },
      async () => {
        const userSignupStatus = await getUserSignupStatus(user.id);
        setSignupStatus(userSignupStatus);
      }
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        const userSignupStatus = await getUserSignupStatus(user.id);
        setSignupStatus(userSignupStatus);
        setIsLoaded(true);
      }
    });
  }, [user, isLoaded, isChannelHealthy]);

  return { signupStatus, isLoaded };
};

export const findUserByHandle = async (
  handle: string
): Promise<Pick<UserInfo, "id" | "firstName"> | undefined> => {
  const { data, error } = await supabase
    .from("user_public_profiles")
    .select("id, first_name, last_name")
    .ilike("handle", handle);
  if (error) {
    logger(error.message, "error");
  }

  if (!data || data?.length === 0) {
    return undefined;
  }

  return {
    id: data[0].id,
    firstName: data[0].first_name,
  };
};

export const findHandleByUserId = async (
  userId: string
): Promise<string | undefined> => {
  const { data, error } = await supabase
    .from("user_public_profiles")
    .select("handle")
    .eq("id", userId);

  if (error) {
    logger(error.message, "error");
  }

  if (!data || data?.length === 0) {
    return undefined;
  }

  return data[0].handle;
};

export const saveVideoToProfile = async ({
  uploadId,
  createPost = false,
}: {
  uploadId: string;
  createPost?: boolean;
}): Promise<{
  error: string | null;
  data?: { playbackId: string; postId?: string };
}> => {
  const res = await fetchAuthWrapper.post("/be-api/create-video-profile", {
    uploadId,
    createPost,
  });
  if (res.status !== 200) {
    logger("Error calling create-video-profile", "error");
    return { error: "Something unexpected happened" };
  }
  const { body } = await res.json();
  const error = body.error;
  if (error) logger(error, "error");
  return {
    error,
    data: { playbackId: body.playback_id, postId: body.post_id },
  };
};

export const removeVideoFromProfile = async (
  userId: string
): Promise<{ error: string | null }> => {
  const { error } = await supabase
    .from("user_public_profiles")
    .update({ playback_id: null })
    .eq("id", userId);

  if (error) {
    logger(error.message, "error");
    return { error: "Something unexpected happened" };
  }

  return { error: null };
};

export const deleteUser = async (): Promise<{
  error: string | null;
}> => {
  const res = await fetchAuthWrapper.post("/be-api/delete-user", {});
  if (res.status !== 200) {
    logger("Error calling delete-user", "error");
    return { error: "Something unexpected happened" };
  }
  return {
    error: null,
  };
};

export const getCommentLikers = async (
  commentId: string,
  pageSize: number,
  pageNumber: number
) => {
  const { data, error } = await supabase.rpc("get_comment_likers", {
    comment_id: commentId,
    page_size: pageSize,
    page_number: pageNumber,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return {
    likers: data[0].likers ? data[0].likers : [],
  };
};

export const getPostLikers = async (
  postId: string,
  pageSize: number,
  pageNumber: number
) => {
  const { data, error } = await supabase.rpc("get_post_likers", {
    post_id: postId,
    page_size: pageSize,
    page_number: pageNumber,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return {
    likers: data[0].likers ? data[0].likers : [],
  };
};

export const useLikes = (commentOrPostId: string, type: "comment" | "post") => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isFinalPage, setIsFinalPage] = useState(false);
  const [likers, setLikers] = useState<
    (Partial<
      Pick<
        UserInfo,
        "id" | "handle" | "fullName" | "shape" | "avatarUrl" | "headline"
      >
    > & { addressId?: string })[]
  >([]);

  const loadMoreLikers = async () => {
    setIsLoaded(false);
    const data =
      type === "comment"
        ? await getCommentLikers(commentOrPostId, 10, pageNumber)
        : await getPostLikers(commentOrPostId, 10, pageNumber);
    setPageNumber(pageNumber + 1);
    setLikers((prevState) => [...prevState, ...data.likers]);
    setIsLoaded(true);
    if (data.likers.length < 10) {
      setIsFinalPage(true);
      return;
    }
    setIsFinalPage(false);
  };

  const resetLikes = () => {
    setPageNumber(1);
    setIsLoaded(false);
    setIsFinalPage(false);
    setLikers([]);
  };

  return { likers, isFinalPage, loadMoreLikers, isLoaded, resetLikes };
};

export const findAllSitemapUserHandles = async (): Promise<string[]> => {
  const { data } = await supabase
    .from("user_public_profiles")
    .select("handle")
    .eq("signup_status", "complete")
    .neq("photo_url", null)
    .neq("first_name", "")
    .neq("headline", "");

  if (!data || data?.length === 0) {
    return [];
  }

  return data.map((user) => user.handle);
};

export const getConsentToProfileAutofill = async (
  userId: string
): Promise<boolean> => {
  const { data, error } = await supabase
    .from("user_public_profiles")
    .select("consent_to_profile_autofill")
    .eq("id", userId)
    .single();

  if (error || !data) {
    logger(error.message, "error");
    return false; // error, or no consent found. This should never be the case.
  }

  return data.consent_to_profile_autofill;
};

export const toggleConsentToProfileAutofill = async (
  userId: string,
  consentValue: boolean
): Promise<{ error: PostgrestError | null }> => {
  const { error } = await supabase
    .from("user_public_profiles")
    .update({
      consent_to_profile_autofill: consentValue,
    })
    .eq("id", userId);

  return { error };
};
