import { HeartSmileyColorPeelIcon } from "Icons/HeartSmileyColorPeel";
import { LoadingAltIcon } from "Icons/LoadingAlt";
import { RibbonPeelIcon } from "Icons/RibbonPeel";

import { useEffect } from "react";

import { css } from "@emotion/react";

import { logger } from "interfaces/logger";

import { purple, uiGray, white } from "constants/colors";
import { errorLogTime } from "constants/time";

import { SpinningIcon } from "./SpinningIcon";
import { Typography } from "./Typography";

const style = {
  card: css({
    margin: "auto",
    boxSizing: "border-box",
    width: 239,
    height: 206,
    padding: 48,
    background: white,
    border: `1px solid ${uiGray[10]}`,
    boxShadow:
      "0px 8px 48px -16px rgba(36, 20, 114, 0.04), 0px 0px 32px rgba(36, 20, 114, 0.02)",
    borderRadius: 24,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  }),
  loadingText: css({
    background: `radial-gradient(84.14% 47.52% at 2.54% 83.13%, #4791FF 0%, ${purple[600]} 100%)`,
    WebkitBackgroundClip: "text",
    MozBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozTextFillColor: "transparent",
  }),
  heartIcon: css({
    position: "absolute",
    top: -23,
    left: 198,
  }),
  ribbonIcon: css({
    position: "absolute",
    top: 110,
    left: -40,
  }),
  line: css({
    position: "absolute",
    top: 140,
    left: -5,
    width: "100%",
    height: "100%",
    display: "flex",
  }),
  lineSvg: css({
    maskType: "alpha",
  }),
};

export const LoadingCard = () => {
  // log error if the component is shown for more than X seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      logger(
        `Loading component is shown for more than ${errorLogTime} milliseconds`,
        "error"
      );
    }, errorLogTime);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div css={style.card}>
      <div css={style.heartIcon}>
        <HeartSmileyColorPeelIcon width={56} height={56} />
      </div>
      <div css={style.ribbonIcon}>
        <RibbonPeelIcon width={56} height={56} />
      </div>

      <div css={style.line}>
        <svg
          width="419"
          height="103"
          viewBox="0 0 419 103"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid="loading-line"
        >
          <mask
            id="mask0_2227_536287"
            css={style.lineSvg}
            maskUnits="userSpaceOnUse"
            x="-1"
            y="-1"
            width="422"
            height="105"
          >
            <path
              d="M0 0.714844C0 42.7148 70.2 90.4148 117 83.2148C175.5 74.2148 160.5 22.2148 128.5 28.7148C96.5 35.2148 109.5 99.7148 176 102.215C242.5 104.715 281.5 40.2148 419 83.2148"
              stroke="url(#paint0_radial_2227_536287)"
              strokeWidth="2"
              strokeLinecap="round"
              strokeDasharray="0.1 8"
            />
          </mask>
          <g mask="url(#mask0_2227_536287)">
            <rect
              y="0.714844"
              width="419"
              height="102"
              fill="url(#paint1_radial_2227_536287)"
            />
          </g>
          <defs>
            <radialGradient
              id="paint0_radial_2227_536287"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(10.63 85.1459) rotate(2.02809) scale(352.783 48.502)"
            >
              <stop stopColor="#4791FF" />
              <stop offset="1" stopColor={purple[600]} />
            </radialGradient>
            <radialGradient
              id="paint1_radial_2227_536287"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(10.63 85.5023) rotate(2.03664) scale(352.785 48.7066)"
            >
              <stop stopColor="#4791FF" />
              <stop offset="1" stopColor={purple[600]} />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <SpinningIcon
        Icon={LoadingAltIcon}
        stroke={purple[600]}
        width={30}
        height={30}
      />
      <Typography size="XL" weight="bold" customCss={style.loadingText}>
        Loading
      </Typography>
    </div>
  );
};
