import { IconProps } from "Icons/types";

import { purple } from "constants/colors";

export const LoadingAltIcon = ({
  width = 16,
  height = 16,
  stroke = purple[600],
  strokeWidth = 2,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="loading-alt-icon"
  >
    <path
      d="M17.5 3.41699V9.25033M17.5 26.7503V32.5837M8.74996 18.0003H2.91663M32.0833 18.0003H26.25M27.8227 28.323L23.6979 24.1982M27.8227 7.79191L23.6979 11.9167M7.17725 28.323L11.302 24.1982M7.17725 7.79191L11.302 11.9167"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
