import { createSlice } from "@reduxjs/toolkit";

import { Action, State } from "./store";

export const followSlice = createSlice({
  name: "followedUserIds",
  initialState: {
    value: [] as string[],
  },
  reducers: {
    addFollowedUserIds: (state, action: Action<string[]>) => {
      state.value.push(...action.payload);
    },
    removeFollowedUserIds: (state, action: Action<string[]>) => {
      state.value = state.value.filter((id) => !action.payload.includes(id));
    },
  },
});

export const { addFollowedUserIds, removeFollowedUserIds } =
  followSlice.actions;

export const selectFollowedUserIds = (state: State, userId: string) =>
  state.followedUserIds.value.includes(userId);

export default followSlice.reducer;
