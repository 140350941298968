import { AlertCircleIcon } from "Icons/AlertCircle";

import { SerializedStyles, css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";

import { red } from "constants/colors";

const style = {
  alert: css({
    display: "flex",
    alignItems: "center",
    gap: 4,
  }),
};

type Props = {
  children: string | React.ReactNode | null;
  customCss?: SerializedStyles;
  size?: "medium" | "small";
  color?: string;
};

export const HelpText = ({
  children,
  customCss,
  size,
  color = red[600],
}: Props) => {
  if (!children) return null;

  return (
    <span css={[style.alert, customCss]}>
      <AlertCircleIcon
        stroke={color}
        width={size === "small" ? 12 : 16}
        height={size === "small" ? 12 : 16}
      />
      <Typography color={color} size={size === "small" ? "XXS" : "S"}>
        {children}
      </Typography>
    </span>
  );
};
