// This util checks if the browser used is a mobile browser.
// From this, we can extrapolate if device uses a mouse and has functionalities like hover.
// This should not be used for viewport width.
export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const webviewRules = [
  "webview|wv|ip((?!.*Safari)|(?=.*like Safari))",
  "WebView",
  "(iPhone|iPod|iPad)(?!.*Safari)",
  "Android.*Version/[0-9].[0-9]",
  "Android.*wv",
  "Linux; U; Android",
];

export const isWebview = () => {
  const userAgent: string | undefined =
    navigator.userAgent || navigator.vendor || (window as any).opera; // eslint-disable-line

  if (userAgent) {
    const regex = new RegExp(`(${webviewRules.join("|")})`, "ig");
    return !!userAgent.match(regex);
  }

  return false;
};

export const isFirefox = () => {
  return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
};
