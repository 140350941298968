import { SerializedStyles, css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";

import { uiGray } from "constants/colors";

const style = {
  label: css({ marginBottom: 8 }),
};

type Props = {
  label: string;
  isOptional?: boolean;
  customCss?: { label?: SerializedStyles; main?: SerializedStyles };
};

export const FieldLabel = ({ label, customCss, isOptional = false }: Props) => {
  return (
    <Typography tag="p" customCss={css(style.label, customCss?.main)}>
      <Typography
        color={uiGray[100]}
        weight="medium"
        customCss={customCss?.label}
      >
        {label}
      </Typography>
      {isOptional && (
        <Typography color={uiGray[50]} weight="medium" size="XXS">
          {" (optional)"}
        </Typography>
      )}
    </Typography>
  );
};
