import { IconProps } from "Icons/types";

import { white } from "constants/colors";

export const LoadingIcon = ({
  width = 16,
  height = 16,
  stroke = white,
  fill = "none",
  strokeWidth = 1.75,
  strokeGradient,
  strokeGradientId,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    stroke={stroke}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="loading-icon"
  >
    {strokeGradient && strokeGradientId && strokeGradient()}
    <path
      d="M7.99992 1.33337V4.00004M7.99992 12V14.6667M3.99992 8.00004H1.33325M14.6666 8.00004H11.9999M12.7189 12.719L10.8333 10.8334M12.7189 3.33334L10.8333 5.21895M3.28097 12.719L5.16659 10.8334M3.28097 3.33334L5.16659 5.21895"
      strokeWidth={strokeWidth}
      stroke={
        strokeGradientId && strokeGradient
          ? `url(#${strokeGradientId})`
          : stroke
      }
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
