import { createSlice } from "@reduxjs/toolkit";

import { Earning } from "apis/earningTransaction";

import { Action, State } from "./store";

export const earningSlice = createSlice({
  name: "earning",
  initialState: {
    value: undefined as Earning | undefined,
  },
  reducers: {
    addEarning: (state, action: Action<Earning>) => {
      state.value = action.payload;
    },
    redeem: (state) => {
      if (!state.value) return;
      // add a new transaction
      state.value.transactions = [
        {
          createdAt: new Date().toISOString(),
          transactionStatus: "redeemed",
          amountInCents: state.value.redeemableEarnings,
          transactionType: "redeemed",
          typeUnit: 1,
        },
        ...state.value.transactions,
      ];
      // set current earnings as 0
      state.value.redeemableEarnings = 0;
    },
  },
});

export const { addEarning, redeem } = earningSlice.actions;

export const selectEarning = (state: State): Earning => {
  return state.earning.value;
};

export default earningSlice.reducer;
