import Script from "next/script";

export const GoogleAdsTrackingTag = () => {
  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=AW-1008657831" />
      <Script id="google-analytics">
        {`
       window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());

       gtag('config', 'AW-1008657831');
     `}
      </Script>
    </>
  );
};
