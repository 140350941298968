import mixpanel, { Dict } from "mixpanel-browser";

import { findAllBucketsUserIsIn } from "utils/ABTesting";

import { logger } from "./logger";

const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || "test_token";
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || "test";

mixpanel.init(mixpanelToken, {
  debug: environment !== "production",
  ignore_dnt: true,
  api_host: "/mixpanel/proxy",
});

export const trackEvent = (eventName: string, properties?: Dict) => {
  if (environment === "production") {
    mixpanel.track(eventName, properties);
  }
};

export const trackButton = (
  label: string,
  pathname: string,
  isSuccess: boolean | void
) => {

  trackEvent("Button", {
    label,
    isSuccess,
    pathname,
    buckets: findAllBucketsUserIsIn(),
  });

  if (!label) {
    logger("Button label is empty", "error");
  }
};

export const identifyUser = (userId: string) => {
  if (environment === "production") {
    mixpanel.identify(userId);
  }
};
