import { createSlice } from "@reduxjs/toolkit";

import { IntegrationJob } from "apis/atsIntegration";

import { Action, State } from "./store";

export const atsJobsSlice = createSlice({
  name: "atsJobs",
  initialState: {
    value: undefined as IntegrationJob[] | undefined,
  },
  reducers: {
    addAtsJobs: (state, action: Action<IntegrationJob[] | undefined>) => {
      state.value = action.payload;
    },
  },
});

export const { addAtsJobs } = atsJobsSlice.actions;

export const selectAtsJobs = (state: State): IntegrationJob[] | undefined => {
  return state.atsJobs.value;
};

export default atsJobsSlice.reducer;
