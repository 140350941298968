import React, { useEffect, useRef } from "react";

import { SerializedStyles, css } from "@emotion/react";

import { trackButton } from "interfaces/tracker";

import { getInnerText } from "utils/text";

type Props = {
  id?: string;
  children: React.ReactNode;
  onClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void> | void;
  onDragEnter?: React.DragEventHandler<HTMLButtonElement>;
  onDragLeave?: React.DragEventHandler<HTMLButtonElement>;
  onDragOver?: React.DragEventHandler<HTMLButtonElement>;
  onDrop?: React.DragEventHandler<HTMLButtonElement>;
  customCss?: SerializedStyles | Array<SerializedStyles | undefined>;
  testId?: string;
  ariaLabel?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  role?: React.AriaRole;
};

const style = css({
  background: "none",
  border: "none",
  cursor: "pointer",
  padding: 0,
  "&:disabled": {
    opacity: 0.5,
    pointerEvents: "none",
  },
});

export const ButtonWrapper = ({
  id,
  children,
  onClick,
  customCss,
  testId = undefined,
  ariaLabel,
  disabled = false,
  autoFocus = false,
  role = "button",
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop,
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (autoFocus && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [autoFocus]);
  return (
    <button
      id={id}
      ref={buttonRef}
      role={role}
      autoFocus={autoFocus}
      aria-label={ariaLabel}
      css={[style, customCss]}
      disabled={disabled}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onClick={(e) => {
        onClick(e);

        trackButton(
          ariaLabel || getInnerText(children),
          window.location.pathname,
          undefined
        );
      }}
      data-testid={testId}
    >
      {children}
    </button>
  );
};
