import { ReactNode, Ref } from "react";

import { SerializedStyles, css } from "@emotion/react";

import { down } from "utils/mediaQueryStrings";

import { uiGray } from "constants/colors";

const getStyle = (
  size: Size,
  mobileSize: Size,
  weight: Weight,
  color: string,
  isHeader: boolean
) =>
  css({
    textAlign: "left",
    fontWeight: weights[weight].fontWeight,
    fontSize: isHeader ? headingSizes[size].fontSize : sizes[size].fontSize,
    lineHeight: isHeader
      ? `${headingSizes[size].lineHeight}px`
      : `${sizes[size].lineHeight}px`,
    [down("mobile")]: {
      fontSize: isHeader
        ? headingSizes[mobileSize].fontSize
        : sizes[mobileSize].fontSize,
      lineHeight: isHeader
        ? `${headingSizes[mobileSize].lineHeight}px`
        : `${sizes[mobileSize].lineHeight}px`,
    },
    margin: 0,
    padding: 0,
    color: color,
    wordBreak: "break-word",
  });

const ellipsis = css({
  "&::before, &::after": {
    display: "inline-block",
    maxWidth: "50%",
    overflow: "hidden",
    whiteSpace: "pre",
  },
  "&::before": {
    content: "attr(data-content-start)",
    textOverflow: "ellipsis",
  },
  "&::after": {
    content: "attr(data-content-end)",
    textOverflow: "''", // Empty string for no ellipsis effect
    direction: "rtl",
  },
});

const sizes: Record<Size, { fontSize: number; lineHeight: number }> = {
  XXS: { fontSize: 12, lineHeight: 16 },
  XS: { fontSize: 14, lineHeight: 20 },
  S: { fontSize: 16, lineHeight: 20 },
  M: { fontSize: 18, lineHeight: 24 },
  L: { fontSize: 20, lineHeight: 26 },
  XL: { fontSize: 24, lineHeight: 32 },
  XXL: { fontSize: 32, lineHeight: 40 },
};

const headingSizes: Record<
  HeadingSize,
  { fontSize: number; lineHeight: number }
> = {
  "3XS": { fontSize: 32, lineHeight: 40 },
  XXS: { fontSize: 36, lineHeight: 48 },
  XS: { fontSize: 40, lineHeight: 52 },
  S: { fontSize: 44, lineHeight: 56 },
  M: { fontSize: 48, lineHeight: 60 },
  L: { fontSize: 56, lineHeight: 72 },
  XL: { fontSize: 64, lineHeight: 84 },
  XXL: { fontSize: 72, lineHeight: 80 },
};

const weights: Record<Weight, { fontWeight: number }> = {
  regular: { fontWeight: 400 },
  medium: { fontWeight: 500 },
  bold: { fontWeight: 700 },
};

export type Size = "XXS" | "XS" | "S" | "M" | "L" | "XL" | "XXL";
type HeadingSize = "3XS" | Size;
type Weight = "regular" | HeadingWeight;
type HeadingWeight = "medium" | "bold";
type HeadingTag = "h1" | "h2";
type Tag = "span" | "p";

export type TypographyProps = {
  dataTestId?: string;
  tag?: Tag | HeadingTag;
  children: ReactNode;
  weight?: Weight | HeadingWeight;
  size?: Size | HeadingSize;
  mobileSize?: Size | HeadingSize;
  color?: string;
  customCss?: SerializedStyles;
  isEllipsis?: boolean;
  refTag?: Ref<HTMLSpanElement | HTMLParagraphElement | HTMLHeadingElement>;
};

const isHeader = (tag: HeadingTag | Tag): boolean => ["h1", "h2"].includes(tag);

export const Typography = ({
  tag = "span",
  children,
  weight = "regular",
  size = "S",
  mobileSize,
  color = uiGray[90],
  customCss,
  refTag,
  isEllipsis = false,
  dataTestId,
}: TypographyProps) => {
  const style = getStyle(
    size as Size,
    mobileSize ? (mobileSize as Size) : (size as Size),
    weight,
    color,
    isHeader(tag)
  );

  const props = {
    "data-testid":
      dataTestId ||
      (isEllipsis && typeof children === "string" ? children : undefined),
    css: [style, customCss, isEllipsis ? ellipsis : undefined],
    "data-content-start":
      isEllipsis && typeof children === "string"
        ? children.substring(0, children.length / 2)
        : undefined,
    "data-content-end":
      isEllipsis && typeof children === "string"
        ? children.substring(children.length / 2, children.length)
        : undefined,
  };

  switch (tag) {
    case "span":
      return (
        <span ref={refTag as Ref<HTMLSpanElement>} {...props}>
          {isEllipsis && typeof children === "string" ? undefined : children}
        </span>
      );
    case "p":
      return (
        <p ref={refTag as Ref<HTMLParagraphElement>} {...props}>
          {isEllipsis && typeof children === "string" ? undefined : children}
        </p>
      );
    case "h1":
      return (
        <h1 ref={refTag as Ref<HTMLHeadingElement>} {...props}>
          {isEllipsis && typeof children === "string" ? undefined : children}
        </h1>
      );
    case "h2":
      return (
        <h2 ref={refTag as Ref<HTMLHeadingElement>} {...props}>
          {isEllipsis && typeof children === "string" ? undefined : children}
        </h2>
      );
  }
};
