import { IconProps } from "Icons/types";

import { red } from "constants/colors";

export const AlertCircleIcon = ({
  width = 16,
  height = 16,
  fill = "none",
  stroke = red[600],
  strokeWidth = 1.5,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    data-testid="alert-circle-icon"
  >
    <g clipPath="url(#clip0_1958_1643)">
      <path
        d="M7.99998 5.33337V8.00004M7.99998 10.6667H8.00665M14.6666 8.00004C14.6666 11.6819 11.6819 14.6667 7.99998 14.6667C4.31808 14.6667 1.33331 11.6819 1.33331 8.00004C1.33331 4.31814 4.31808 1.33337 7.99998 1.33337C11.6819 1.33337 14.6666 4.31814 14.6666 8.00004Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1958_1643">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
