import { useEffect, useState } from "react";

export type PasswordErrorState = "blank" | "invalid" | "valid" | "incorrect";
export type EmailErrorState =
  | "blank"
  | "invalid"
  | "valid"
  | "no-exist"
  | "unconfirmed";
export type ConfirmationPhraseErrorState = "blank" | "invalid" | "valid";

export const getPasswordHelpText = (errorState: PasswordErrorState): string => {
  switch (errorState) {
    case "blank":
      return "Please enter a password";
    case "invalid":
      return "Please enter a valid password (at least 6 characters)";
    case "incorrect":
      return "Password is incorrect";
    case "valid":
      return "";
  }
};

export const getEmailHelpText = (errorState: EmailErrorState): string => {
  switch (errorState) {
    case "no-exist":
      return "Your email and password combination is incorrect";
    case "blank":
      return "Please enter an email";
    case "invalid":
      return "Please enter a valid email address";
    case "unconfirmed":
      return "Please check your email and junk folder to confirm your email and login.";
    case "valid":
      return "";
  }
};

export const getConfirmationHelpText = (
  errorState: ConfirmationPhraseErrorState
): string => {
  switch (errorState) {
    case "blank":
      return "Please enter a confirmation phrase";
    case "invalid":
      return "Please enter a valid confirmation phrase";
    case "valid":
      return "";
  }
};

export const emailValidator = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function useEmailValidation(email: string, timeout = 1000) {
  const [isEmailError, setIsEmailError] = useState(false);

  useEffect(() => {
    // immediately return if email is valid
    if (emailValidator(email)) {
      setIsEmailError(false);
      return;
    }

    // otherwise, set a timeout to show error
    const timeOutId = setTimeout(() => {
      if (email.length && !emailValidator(email)) {
        setIsEmailError(true);
      }
    }, timeout);

    return () => clearTimeout(timeOutId);
  }, [email, timeout]);

  return isEmailError;
}

export const handleValidator = (handle: string) => {
  // only allow letters (upper and lower case) and underscore, since it is what twitter does
  const re = /^[\w]+$/;
  // max char for handles is 15
  return re.test(String(handle)) && handle.length <= 15;
};

export const passwordValidator = (password: string) => {
  return password.length >= 6;
};

export const ashbyApiKeyValidator = (password: string) => {
  return password.length === 64;
};

export const nameValidator = (name: string): boolean => {
  // Remove leading and trailing whitespaces
  const trimmedName = name.trim();

  // Remove all special characters, including non-breaking spaces
  const cleanedName = trimmedName
    .replace(/[^\w\s]/gi, "")
    .replace(/[\u200E\W]/g, "");

  // Check if the cleaned name has more than 1 character
  return cleanedName.length > 0;
};

export const cleanName = (name: string): string => {
  return name
    .trim()
    .replace(/[^\w\s]/gi, "")
    .replace(/[\u200E\W]/g, "");
};

export function usePasswordValidation(password: string, timeout = 1000) {
  const [isPasswordError, setIsPasswordError] = useState(false);

  useEffect(() => {
    // immediately return if password is valid
    if (passwordValidator(password)) {
      setIsPasswordError(false);
      return;
    }

    // otherwise, set a timeout to show error
    const timeOutId = setTimeout(() => {
      if (password.length && !passwordValidator(password)) {
        setIsPasswordError(true);
      }
    }, timeout);

    return () => clearTimeout(timeOutId);
  }, [password, timeout]);

  return isPasswordError;
}

export const confirmationPhraseValidator = (
  phrase: string,
  correctConfirmationPhrase: string
) => {
  return phrase === correctConfirmationPhrase;
};

export function useConfirmationPhraseValidation({
  phrase,
  confirmationPhrase,
  timeout = 1000,
  reset,
}: {
  phrase: string;
  confirmationPhrase: string;
  timeout?: number;
  reset?: boolean;
}) {
  const [isConfirmationError, setIsConfirmationError] = useState(false);

  useEffect(() => {
    // reset error state if reset is true
    if (reset) {
      setIsConfirmationError(false);
      return;
    }

    // immediately return if confirmation phrase is valid
    if (confirmationPhraseValidator(phrase, confirmationPhrase)) {
      setIsConfirmationError(false);
      return;
    }

    // otherwise, set a timeout to show error
    const timeOutId = setTimeout(() => {
      if (confirmationPhrase.length && phrase !== confirmationPhrase) {
        setIsConfirmationError(true);
      }
    }, timeout);

    return () => clearTimeout(timeOutId);
  }, [confirmationPhrase, phrase, timeout, reset]);

  return isConfirmationError;
}
