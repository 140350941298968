import { IconProps } from "Icons/types";

export const CheckColorIcon = ({ width = 24, height = 24 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="check-color-icon"
  >
    <g filter="url(#filter0_dd_1264_21891)">
      <path
        d="M10.856 1.77955C11.66 1.38924 12.5985 1.38924 13.4024 1.77955L18.6139 4.30983C19.2022 4.59547 19.6774 5.07064 19.963 5.65896L22.4933 10.8705C22.8836 11.6744 22.8836 12.6129 22.4933 13.4168L19.963 18.6283C19.6774 19.2166 19.2022 19.6918 18.6139 19.9774L13.4024 22.5077C12.5985 22.898 11.66 22.898 10.856 22.5077L5.64455 19.9774C5.05624 19.6918 4.58106 19.2166 4.29543 18.6283L1.76515 13.4168C1.37483 12.6129 1.37483 11.6744 1.76515 10.8705L4.29543 5.65896C4.58106 5.07064 5.05624 4.59547 5.64455 4.30983L10.856 1.77955Z"
        fill="#72CF86"
      />
      <path
        d="M11.1181 2.3193C11.7565 2.00932 12.5019 2.00932 13.1403 2.3193L18.3518 4.84958C18.8191 5.07642 19.1964 5.45379 19.4233 5.92101L21.9536 11.1325C22.2635 11.771 22.2635 12.5163 21.9536 13.1547L19.4233 18.3662C19.1964 18.8335 18.8191 19.2108 18.3518 19.4377L13.1403 21.968C12.5019 22.2779 11.7565 22.2779 11.1181 21.968L5.90661 19.4377C5.43938 19.2108 5.06202 18.8335 4.83517 18.3662L2.30489 13.1547C1.99492 12.5163 1.99492 11.771 2.30489 11.1325L4.83517 5.92101C5.06202 5.45379 5.43939 5.07642 5.90661 4.84958L11.1181 2.3193Z"
        stroke="#04C250"
        strokeWidth="1.2"
      />
    </g>
    <path
      d="M16.6994 11.078C17.3689 10.4223 17.3612 9.35797 16.6832 8.71151C16.0168 8.07608 14.9514 8.08273 14.2934 8.7272L10.9733 11.9791L9.9147 10.9423C9.25672 10.2979 8.1913 10.2912 7.52489 10.9266C6.84691 11.5731 6.83922 12.6375 7.50866 13.2932L9.77024 15.5083C10.0913 15.8227 10.5249 15.9961 10.9733 15.9961C11.4216 15.9961 11.8553 15.8227 12.1763 15.5083L16.6994 11.078Z"
      fill="white"
      stroke="#04C250"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_dd_1264_21891"
        x="0.499439"
        y="1.16249"
        width="23.2596"
        height="23.2596"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="0.324324"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_1264_21891"
        />
        <feOffset dy="0.324324" />
        <feGaussianBlur stdDeviation="0.324324" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1264_21891"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="0.324324"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_1264_21891"
        />
        <feOffset dy="0.648649" />
        <feGaussianBlur stdDeviation="0.648649" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0 0.105882 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1264_21891"
          result="effect2_dropShadow_1264_21891"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1264_21891"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
