import { css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";

import { purple, uiGray } from "constants/colors";

import { ButtonWrapper } from "./ButtonWrapper";

const style = {
  form: css({
    display: "flex",
    flexDirection: "column",
    gap: 12,
  }),
  ul: css({
    listStyleType: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    gap: 6,
  }),
  li: css({
    listStyle: "none",
    flex: 1,
  }),
  button: css({
    height: 56,
    borderRadius: 6,
    backgroundColor: purple[100],
    width: "-webkit-fill-available",
  }),
  selected: css({
    border: `1px solid ${purple[600]}`,
    backgroundColor: purple[200],
  }),
  legend: css({
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  }),
};

type Props = { onChange: (value: number) => void; value?: number };
export const SatisfactionRatingField = ({ onChange, value }: Props) => {
  return (
    <div css={style.form}>
      <Typography weight="medium" size="XS">
        Rate your experience
      </Typography>
      <div>
        <ul css={style.ul}>
          {["😠", "😞", "😐", "🙂", "😁"].map((emoji, index) => (
            <li css={style.li} key={emoji}>
              <ButtonWrapper
                ariaLabel={emoji}
                customCss={[
                  style.button,
                  value === index + 1 ? style.selected : undefined,
                ]}
                onClick={() => {
                  // if the value did not change, do nothing
                  if (value === index + 1) return;
                  // otherwise call onChange
                  onChange(index + 1);
                }}
              >
                <Typography size="M">{emoji}</Typography>
              </ButtonWrapper>
            </li>
          ))}
        </ul>
        <div css={style.legend}>
          <Typography color={uiGray[70]} size="XS">
            Not satisfied
          </Typography>
          <Typography color={uiGray[70]} size="XS">
            Very satisfied
          </Typography>
        </div>
      </div>
    </div>
  );
};
