import { css } from "@emotion/react";

import { RibbonLogo } from "components/shared/library/RibbonLogo";
import { Typography } from "components/shared/library/Typography";

import { down, up } from "utils/mediaQueryStrings";

import { gradient, uiGray, white } from "constants/colors";

const style = {
  background: css({
    background: gradient.background,
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    overflow: "auto",
    overflowX: "hidden",
  }),
  card: css({
    margin: "auto",
    height: "fit-content",
    flexDirection: "column",
    boxSizing: "border-box",
    background: white,
    border: `1px solid ${uiGray[10]}`,
    boxShadow:
      "0px 8px 48px -16px rgba(36, 20, 114, 0.04), 0px 0px 32px rgba(36, 20, 114, 0.02)",
    borderRadius: 24,
    [down("mobile")]: {
      width: 358,
      height: "fit-content",
      margin: "16px auto 50px",
      padding: "48px 32px",
    },
    [up("mobile")]: {
      padding: 48,
      width: 640,
    },
  }),
  logo: css({
    height: 24,
    marginBottom: 40,
  }),
  titleWrapper: css({
    marginBottom: 40,
  }),
  title: css({
    [down("mobile")]: {
      fontSize: 24,
      lineHeight: "32px",
    },
  }),
  subtitle: css({
    marginTop: 8,
  }),
};

type Props = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
};

export const ErrorCard = ({ title, subtitle, children }: Props) => {
  return (
    <div css={style.background}>
      <div css={style.card}>
        <div css={style.logo}>
          <RibbonLogo isLink />
        </div>
        <div css={style.titleWrapper}>
          <Typography
            tag="h1"
            color={uiGray[100]}
            weight="bold"
            size="3XS"
            customCss={style.title}
          >
            {title}
          </Typography>

          <Typography
            tag="p"
            color={uiGray[80]}
            weight="regular"
            size="S"
            customCss={style.subtitle}
          >
            {subtitle}
          </Typography>
        </div>
        {children}
      </div>
    </div>
  );
};
