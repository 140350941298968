import { Background, Color, Size } from "components/shared/library/Button";

import {
  black,
  gradient,
  purple,
  red,
  uiBlueGray,
  uiGray,
  white,
  yellow,
} from "./colors";

const placeholderColorSet = {
  text: black,
  background: black,
  border: black,
  outline: black,
};

export const buttonColorMatrix: Record<
  Color,
  Record<
    Background,
    Record<
      "default" | "hover" | "focus" | "disabled",
      Record<"text" | "background" | "border" | "outline", string>
    >
  >
> = {
  purple: {
    filled: {
      default: {
        text: white,
        background: gradient.main,
        border: gradient.main,
        outline: "transparent",
      },
      hover: {
        text: white,
        background: gradient.dark,
        border: gradient.dark,
        outline: "transparent",
      },
      focus: {
        text: white,
        background: gradient.dark,
        border: gradient.dark,
        outline: purple.focusShadow,
      },
      disabled: {
        text: white,
        background: gradient.light,
        border: gradient.light,
        outline: "transparent",
      },
    },
    ghost: {
      default: {
        text: gradient.main,
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      hover: {
        text: gradient.main,
        background: "#F2F6FF",
        border: "transparent",
        outline: "transparent",
      },
      focus: {
        text: gradient.main,
        background: "#F2F6FF",
        border: "transparent",
        outline: purple.focusShadow,
      },
      disabled: {
        text: gradient.light,
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
    },
    link: {
      default: {
        text: gradient.main,
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      hover: {
        text: gradient.dark,
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      focus: {
        text: gradient.dark,
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      disabled: {
        text: gradient.light,
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
    },
    outlined: {
      default: {
        text: gradient.main,
        background: "transparent",
        border: gradient.main,
        outline: "transparent",
      },
      hover: {
        text: gradient.main,
        background: "#F2F6FF",
        border: gradient.main,
        outline: "transparent",
      },
      focus: {
        text: gradient.main,
        background: "#F2F6FF",
        border: gradient.main,
        outline: purple.focusShadow,
      },
      disabled: {
        text: gradient.light,
        background: "transparent",
        border: gradient.light,
        outline: "transparent",
      },
    },
  },
  black: {
    filled: {
      default: {
        text: white,
        background: uiGray[100],
        border: uiGray[100],
        outline: "transparent",
      },
      hover: {
        text: white,
        background: uiGray[90],
        border: uiGray[90],
        outline: "transparent",
      },
      focus: {
        text: white,
        background: uiGray[90],
        border: uiGray[90],
        outline: "#E1E1E1",
      },
      disabled: {
        text: uiGray[40],
        background: uiGray[10],
        border: uiGray[10],
        outline: "transparent",
      },
    },
    ghost: {
      default: {
        text: uiGray[100],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      hover: {
        text: uiGray[100],
        background: uiGray[10],
        border: uiGray[10],
        outline: "transparent",
      },
      focus: {
        text: uiGray[100],
        background: uiGray[10],
        border: uiGray[10],
        outline: "#E1E1E1",
      },
      disabled: {
        text: "#A8A8A8",
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
    },
    link: {
      default: {
        text: uiGray[100],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      hover: {
        text: uiGray[80],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      focus: {
        text: uiGray[80],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      disabled: {
        text: "#A8A8A8",
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
    },
    outlined: {
      default: {
        text: uiGray[100],
        background: "transparent",
        border: uiGray[100],
        outline: "transparent",
      },
      hover: {
        text: uiGray[100],
        background: uiGray[10],
        border: uiGray[100],
        outline: "transparent",
      },
      focus: {
        text: uiGray[100],
        background: uiGray[10],
        border: uiGray[100],
        outline: "#E1E1E1",
      },
      disabled: {
        text: "#A8A8A8",
        background: "transparent",
        border: uiGray[30],
        outline: "transparent",
      },
    },
  },
  red: {
    filled: {
      default: {
        text: white,
        background: red[600],
        border: red[600],
        outline: "transparent",
      },
      hover: {
        text: white,
        background: red[700],
        border: red[700],
        outline: "transparent",
      },
      focus: {
        text: white,
        background: red[700],
        border: red[700],
        outline: "#FCEBEB",
      },
      disabled: {
        text: red[400],
        background: red[100],
        border: red[100],
        outline: "transparent",
      },
    },
    ghost: {
      default: {
        text: red[600],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      hover: {
        text: red[600],
        background: red[100],
        border: "transparent",
        outline: "transparent",
      },
      focus: {
        text: red[600],
        background: red[100],
        border: "transparent",
        outline: "#FCEBEB",
      },
      disabled: {
        text: uiGray[30],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
    },
    link: {
      default: {
        text: red[600],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      hover: {
        text: red[700],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      focus: {
        text: red[700],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      disabled: {
        text: uiGray[30],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
    },
    outlined: {
      default: {
        text: red[600],
        background: "transparent",
        border: red[600],
        outline: "transparent",
      },
      hover: {
        text: red[600],
        background: red[100],
        border: red[600],
        outline: "transparent",
      },
      focus: {
        text: red[600],
        background: red[100],
        border: red[600],
        outline: "#FCEBEB",
      },
      disabled: {
        text: uiGray[30],
        background: "transparent",
        border: uiGray[30],
        outline: "transparent",
      },
    },
  },
  yellow: {
    filled: {
      default: {
        text: black,
        background: yellow[400],
        border: yellow[400],
        outline: "transparent",
      },
      hover: {
        text: black,
        background: yellow[500],
        border: yellow[500],
        outline: "transparent",
      },
      focus: {
        text: black,
        background: yellow[500],
        border: yellow[500],
        outline: "#FCEBEB",
      },
      disabled: {
        text: uiGray[40],
        background: `${yellow[400]}30`,
        border: `${yellow[400]}30`,
        outline: "transparent",
      },
    },
    ghost: {
      default: placeholderColorSet,
      hover: placeholderColorSet,
      focus: placeholderColorSet,
      disabled: placeholderColorSet,
    },
    link: {
      default: placeholderColorSet,
      hover: placeholderColorSet,
      focus: placeholderColorSet,
      disabled: placeholderColorSet,
    },
    outlined: {
      default: placeholderColorSet,
      hover: placeholderColorSet,
      focus: placeholderColorSet,
      disabled: placeholderColorSet,
    },
  },
  gray: {
    filled: {
      default: {
        text: uiGray[70],
        background: uiGray.pageBackground,
        border: uiGray.pageBackground,
        outline: "transparent",
      },
      hover: {
        text: uiGray[80],
        background: uiBlueGray[100],
        border: uiBlueGray[100],
        outline: "transparent",
      },
      focus: {
        text: uiGray[80],
        background: uiBlueGray[100],
        border: uiBlueGray[100],
        outline: "#E1E1E1",
      },
      disabled: {
        text: uiGray[30],
        background: uiBlueGray[50],
        border: uiBlueGray[50],
        outline: "transparent",
      },
    },
    ghost: {
      default: {
        text: uiGray[70],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      hover: {
        text: uiGray[80],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      focus: {
        text: uiGray[80],
        background: "transparent",
        border: "transparent",
        outline: "#E1E1E1",
      },
      disabled: {
        text: uiGray[30],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
    },
    link: {
      default: {
        text: uiGray[70],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      hover: {
        text: uiGray[80],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      focus: {
        text: uiGray[80],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
      disabled: {
        text: uiGray[30],
        background: "transparent",
        border: "transparent",
        outline: "transparent",
      },
    },
    outlined: {
      default: {
        text: uiGray[70],
        background: white,
        border: uiGray[20],
        outline: "transparent",
      },
      hover: {
        text: uiGray[80],
        background: uiBlueGray[50],
        border: uiGray[20],
        outline: "transparent",
      },
      focus: {
        text: uiGray[80],
        background: white,
        border: uiGray[20],
        outline: "#E1E1E1",
      },
      disabled: {
        text: uiGray[30],
        background: white,
        border: uiGray[20],
        outline: "transparent",
      },
    },
  },
};

export const buttonSizes: Record<
  Size,
  Record<
    | "paddingVertical"
    | "paddingHorizontal"
    | "height"
    | "fontWeight"
    | "fontSize"
    | "iconSize"
    | "iconStrokeWidth"
    | "textPadding",
    number
  >
> = {
  large: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    height: 48,
    fontWeight: 700,
    fontSize: 16,
    iconSize: 16,
    iconStrokeWidth: 1.75,
    textPadding: 8,
  },
  medium: {
    paddingVertical: 10,
    paddingHorizontal: 18,
    height: 40,
    fontWeight: 700,
    fontSize: 16,
    iconSize: 16,
    iconStrokeWidth: 1.75,
    textPadding: 8,
  },
  small: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    height: 32,
    fontWeight: 700,
    fontSize: 14,
    iconSize: 12,
    iconStrokeWidth: 1.4,
    textPadding: 6,
  },
  "extra-small": {
    paddingVertical: 10,
    paddingHorizontal: 14,
    height: 24,
    fontWeight: 500,
    fontSize: 12,
    iconSize: 12,
    iconStrokeWidth: 1.4,
    textPadding: 8,
  },
  XXS: {
    paddingVertical: 10,
    paddingHorizontal: 4,
    height: 20,
    fontWeight: 500,
    fontSize: 12,
    iconSize: 12,
    iconStrokeWidth: 2,
    textPadding: 4,
  },
};
