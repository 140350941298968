import { createSlice } from "@reduxjs/toolkit";

import { Action, State } from "./store";

export const invitesSlice = createSlice({
  name: "invites",
  initialState: {
    value: { sentCount: undefined } as { sentCount?: number },
  },
  reducers: {
    updateCount: (state, action: Action<number>) => {
      // this sets the counter value
      state.value.sentCount = action.payload;
    },
    incrementCount: (state, action: Action<number>) => {
      // this adds to the existing counter value
      state.value.sentCount = (state.value.sentCount || 0) + action.payload;
    },
    decrementCount: (state, action: Action<number>) => {
      // this subtracts to the existing counter value
      state.value.sentCount = (state.value.sentCount || 0) - action.payload;
    },
  },
});

export const { updateCount, incrementCount, decrementCount } =
  invitesSlice.actions;

export const selectInvitesSentCount = (state: State): number | undefined => {
  return state.invites.value.sentCount;
};

export default invitesSlice.reducer;
