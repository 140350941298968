import { createSlice } from "@reduxjs/toolkit";

import { Action, State } from "./store";

export type sysAdminRoles = "delete-comment" | "delete-post" | "delete-user";

export const sysAdminSlice = createSlice({
  name: "sysAdminRoles",
  initialState: {
    value: [] as sysAdminRoles[],
  },
  reducers: {
    addSysAdminRoles: (state, action: Action<sysAdminRoles[]>) => {
      state.value.push(...action.payload);
    },
  },
});

export const { addSysAdminRoles } = sysAdminSlice.actions;

export const selectSysAdminRole = (state: State, role: sysAdminRoles) =>
  state.sysAdminRoles.value.includes(role);

export default sysAdminSlice.reducer;
