import React, { useCallback, useContext, useState } from "react";

import { Alert } from "components/shared/library/Alert";

const ALERT_TIME = 5000;

const AlertContext = React.createContext<Context>({
  createAlert: () => undefined,
});

export type AlertType = "success" | "warning" | "error";
export type AlertTextType = string;

type Context = {
  createAlert: (
    notificationType: AlertType,
    notificationText: AlertTextType
  ) => void;
};

type Props = {
  children: React.ReactNode;
};

export const AlertProvider = ({ children }: Props) => {
  const [notificationType, setNotificationType] = useState<AlertType | null>(
    null
  );
  const [notificationText, setNotificationText] =
    useState<AlertTextType | null>(null);

  const createAlert = useCallback(
    (notificationType: AlertType, notificationText: AlertTextType) => {
      setNotificationType(notificationType);
      setNotificationText(notificationText);

      setTimeout(() => {
        setNotificationType(null);

        setNotificationText(null);
      }, ALERT_TIME);
    },
    []
  );

  return (
    <AlertContext.Provider
      value={{
        createAlert,
      }}
    >
      {children}

      {notificationText && notificationType && (
        <Alert alertText={notificationText} alertType={notificationType} />
      )}
    </AlertContext.Provider>
  );
};

export default AlertContext;

export const useAlert = () => useContext(AlertContext);
