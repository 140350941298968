import { useEffect, useState } from "react";

import { PostgrestError } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";
import { trackEvent } from "interfaces/tracker";

import { supabase } from "apis/supabaseClient";

import { useRealtimeChannelHealth } from "utils/realtime";
import { getRecruitUrl } from "utils/urls";

import { fetchAuthWrapper } from "./backend";

export type ProfileAutoFillStatus = "in_progress" | "completed" | "not_started";

type Onboarding = {
  isComplete: boolean;
  isRecruitComplete: boolean;
};

export const findOnboarding = async (
  userId: string
): Promise<Onboarding | undefined> => {
  const { data, error } = await supabase
    .from("onboardings")
    .select()
    .eq("user_id", userId);

  if (error) {
    logger(error.message, "error");
    return;
  }

  return {
    isComplete: data[0].is_complete,
    isRecruitComplete: data[0].is_recruit_complete,
  };
};

export const isOnboardingComplete = async (
  userId: string,
  currentPath = "/"
): Promise<boolean> => {
  const onboarding = await findOnboarding(userId);
  if (!onboarding) return false;

  if (currentPath.includes(getRecruitUrl()))
    return onboarding.isRecruitComplete;

  return onboarding.isComplete;
};

export const getProfileAutoFillStatus = async (
  userId: string
): Promise<ProfileAutoFillStatus> => {
  const { data, error } = await supabase
    .from("onboardings")
    .select()
    .eq("user_id", userId);

  if (error) {
    logger(error.message, "error");
  }

  if (!data?.[0]) return "not_started";

  return data[0].profile_auto_fill_status;
};

export const completeOnboarding = async (
  userId: string
): Promise<{ error: PostgrestError | null }> => {
  trackEvent("OnboardingComplete");
  const { error } = await supabase
    .from("onboardings")
    .update({ is_complete: true })
    .eq("user_id", userId);
  return { error };
};

export const completeRecruitOnboarding = async (
  userId: string
): Promise<{ error: PostgrestError | null }> => {
  trackEvent("RecruitOnboardingComplete");
  const { error } = await supabase
    .from("onboardings")
    .update({ is_recruit_complete: true })
    .eq("user_id", userId);
  return { error };
};

export const autoFillProfile = async (): Promise<{ error?: string }> => {
  const res = await fetchAuthWrapper.post("/be-api/auto-fill-profile", {});

  if (res.status !== 200) {
    logger("Error redeeming earnings", "error");
    return { error: "Something unexpected happened" };
  }

  return {};
};

type OnboardingLoaded = {
  isLoaded: true;
  profileAutoFillStatus: ProfileAutoFillStatus;
};
type OnboardingLoading = { isLoaded: false; profileAutoFillStatus: undefined };

export const useOnboardingRealtime = (
  userId: string
): OnboardingLoaded | OnboardingLoading => {
  const [profileAutoFillStatus, setProfileAutoFillStatus] =
    useState<ProfileAutoFillStatus>();
  const onboardingChannelName = "db-onboarding";
  const isChannelHealthy = useRealtimeChannelHealth(
    onboardingChannelName,
    !!userId
  );

  useEffect(() => {
    if (!userId || isChannelHealthy) return;

    const channel = supabase.channel(onboardingChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "onboardings",
        filter: `user_id=eq.${userId}`,
      },
      async (payload) => {
        setProfileAutoFillStatus(payload.new.profile_auto_fill_status);
      }
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        const status = await getProfileAutoFillStatus(userId);
        setProfileAutoFillStatus(status);
      }
    });

    return () => {
      channel.unsubscribe();
    };
  }, [userId, isChannelHealthy]);

  // we check that the value is not undefined, to deduce it is loaded
  if (typeof profileAutoFillStatus !== "undefined") {
    return { profileAutoFillStatus, isLoaded: true };
  } else {
    return { profileAutoFillStatus: undefined, isLoaded: false };
  }
};
