import { addToLocalStorage, getFromLocalStorage } from "./localStorage";

// this experiment is no longer on-going, keeping the code here as a reference
// note that users are still bucketed, but no matter which bucket they are in they can skip linkedIn
const onGoingExperimentBuckets = {
  "force-linkedin-import": [{ value: "force" }, { value: "do-not-force" }], // this data structure is to allow for adding a bias prop in the future
};

export const addToABBuckets = () => {
  // avoid issues on SSR
  if (!globalThis.localStorage) return;
  let experimentKey: keyof typeof onGoingExperimentBuckets;
  for (experimentKey in onGoingExperimentBuckets) {
    const isBucketed = getFromLocalStorage(experimentKey);
    if (!isBucketed) {
      const buckets = onGoingExperimentBuckets[experimentKey];
      const bucket = buckets[Math.floor(Math.random() * buckets.length)];
      addToLocalStorage(experimentKey, bucket.value);
    }
  }
};

// this is an abstraction of local storage
export const retrieveABBucket = (
  experimentKey: keyof typeof onGoingExperimentBuckets
) => {
  // avoid issues on SSR
  if (!globalThis.localStorage) return;
  return getFromLocalStorage(experimentKey);
};

export const findAllBucketsUserIsIn = () => {
  // avoid issues on SSR
  if (!globalThis.localStorage) return;
  let experimentKey: keyof typeof onGoingExperimentBuckets;
  const buckets = [];
  for (experimentKey in onGoingExperimentBuckets) {
    const bucket = getFromLocalStorage(experimentKey);
    if (bucket) {
      buckets.push(`${experimentKey}~~${bucket}`);
    }
  }
  return buckets;
};
