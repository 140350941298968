import {
  DragEventHandler,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { SerializedStyles, css } from "@emotion/react";

import { isMobile } from "utils/device";
import { useMaxChar } from "utils/libraryHooks";

import { purple, red, uiGray, white } from "constants/colors";

import { FieldLabel } from "./FieldLabel";
import { HelpText } from "./HelpText";
import { MaxCharCounter } from "./MaxCharCounter";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    position: "relative",
  }),
  label: css({ marginBottom: 8 }),
  input: css({
    padding: "12px 14px",
    background: white,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: uiGray[10],
    borderRadius: 12,
    "&:focus": {
      outline: `1px solid ${purple[600]}`,
      boxShadow: `0px 0px 0px 4px ${purple.focusShadow}`,
    },
    "&::placeholder": {
      color: uiGray[60],
    },
    "&:disabled": {
      color: uiGray[50],
      background: "rgba(141, 141, 141, 0.05)",
      borderColor: "#ECECEC",
    },
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    color: uiGray[100],
  }),
  error: css({
    outline: `1px solid ${red[600]}!important`,
    boxShadow: `0px 0px 0px 4px ${red.focusError}!important`,
  }),
  counter: css({ marginTop: 6, marginRight: 4, textAlign: "end" }),
  fixedSize: css({ resize: "none" }),
  helpers: css({ display: "flex", justifyContent: "space-between" }),
  helpTextWrapper: css({ flex: 1 }),
  helpText: css({ marginTop: 6, marginLeft: 4 }),
};

type Props = {
  id?: string;
  onChange: (value: string) => void;
  value: string;
  label: string;
  placeholder?: string;
  isError?: boolean;
  isDisabled?: boolean;
  displayLabel?: boolean;
  onEnter?: () => void;
  onFocus?: (e: FocusEvent<HTMLTextAreaElement, Element> | undefined) => void;
  maxChar?: number;
  isOptional?: boolean;
  resize?: "none" | "horizontal" | "both" | "vertical";
  autoFocus?: boolean;
  customCss?: {
    label?: SerializedStyles;
    textArea?: SerializedStyles;
    wrapper?: SerializedStyles;
  };
  rows?: number; // this is the number of visible rows - https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea#attr-rows
  onDrop?: DragEventHandler<HTMLTextAreaElement>;
  onDragEnter?: DragEventHandler<HTMLTextAreaElement>;
  onDragLeave?: DragEventHandler<HTMLTextAreaElement>;
  isExpandable?: boolean;
  maxRows?: number;
  helpText?: string;
};

export const TextArea = ({
  onChange,
  value,
  label,
  placeholder = "",
  isDisabled,
  isError,
  displayLabel = false,
  onEnter,
  maxChar,
  isOptional = false,
  onFocus,
  resize = "none",
  id,
  autoFocus,
  customCss,
  rows = 1,
  maxRows,
  onDrop,
  onDragEnter,
  onDragLeave,
  isExpandable,
  helpText,
}: Props) => {
  const [maxCharExceeded, setMaxCharExceeded] = useMaxChar();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleHeightChange = useCallback(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "";
      textarea.style.height =
        Math.min(
          textarea.scrollHeight - 24,
          maxRows ? maxRows * 20 : Infinity
        ) + "px"; // 24 is extra padding around the input field
    }
  }, [maxRows]);

  useEffect(() => {
    handleHeightChange();
  }, [isExpandable, handleHeightChange]);

  return (
    <div css={customCss?.wrapper}>
      {displayLabel && (
        <FieldLabel
          label={label}
          isOptional={isOptional}
          customCss={{ label: customCss?.label }}
        />
      )}
      <div css={style.wrapper}>
        <textarea
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          ref={textareaRef}
          rows={rows}
          id={id}
          autoComplete="off"
          autoFocus={autoFocus}
          onFocus={onFocus}
          role="textbox"
          aria-label={label}
          disabled={isDisabled}
          placeholder={placeholder}
          css={[
            style.input,
            customCss?.textArea,
            (isError || maxCharExceeded) && style.error,
            css({ resize }),
          ]}
          onChange={(e) => {
            onChange(e.target.value.slice(0, maxChar));
            setMaxCharExceeded(e.target.value.length > (maxChar || Infinity));
            if (isExpandable) handleHeightChange();
          }}
          value={value.slice(0, maxChar)}
          onKeyUp={(e) => {
            if (e.key === "Enter" && e.shiftKey && !isMobile()) {
              return;
            } else if (e.key === "Enter" && onEnter) {
              onEnter();
            }
          }}
        ></textarea>
      </div>
      <div css={style.helpers}>
        <div css={style.helpTextWrapper}>
          <HelpText
            size="small"
            customCss={style.helpText}
            color={isError ? red[600] : uiGray[90]}
          >
            {helpText || null}
          </HelpText>
        </div>
        {maxChar && <MaxCharCounter maxChar={maxChar} value={value} />}
      </div>
    </div>
  );
};
