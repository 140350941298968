import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { css } from "@emotion/react";

import { AnnotationQuestionIcon } from "Icons/AnnotationQuestion";

import { Typography } from "components/shared/library/Typography";

import { useCloseOnClickOutside } from "utils/libraryHooks";

import {
  SLIDE_TRANSITION_DURATION,
  SLIDE_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { uiGray, white } from "constants/colors";

import { ButtonWrapper } from "../library/ButtonWrapper";
import { SupportFeedback } from "./SupportFeedback";
import { SupportMenu } from "./SupportMenu";

const getStyle = (tab: "menu" | "feedback") => ({
  wrapper: css({
    position: "fixed",
    bottom: 19,
    right: 19,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: 8,
    "& .support-popup-transition-enter": {
      height: 0,
    },
    "& .support-popup-transition-enter-active": {
      height: tab === "menu" ? 172 : 540,
      transition: `height ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
    },
    "& .support-popup-transition-exit": {
      height: tab === "menu" ? 172 : 540,
    },
    "& .support-popup-transition-exit-active": {
      height: 0,
      opacity: 0,
      transition: `height ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}, opacity 0s ${SLIDE_TRANSITION_TIMING_FUNCTION} ${SLIDE_TRANSITION_DURATION}`,
    },
  }),
  popup: css({
    height: tab === "menu" ? 172 : 540,
    background: white,
    width: tab === "menu" ? 281 : 340,
    boxShadow: "0px 0px 0px 1px #F5F6FA, 0px 0px 8px rgba(72, 40, 228, 0.15)",
    borderRadius: 12,
    overflow: "hidden",
  }),
  button: css({
    display: "flex",
    alignItems: "center",
    padding: "10px 12px",
    background: white,
    border: `1px solid ${uiGray[10]}`,
    boxShadow: "0px 1px 2px rgba(27, 27, 27, 0.05)",
    borderRadius: 100,
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&:hover": {
      padding: "10px 18px",
    },
    "&:hover .support-label": {
      width: 69, // 61 is the typography width + 8px margin
      marginLeft: 8,
    },
  }),
  supportLabel: css({
    overflow: "hidden",
    width: 0,
    transition: `width ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
  }),
});

export const SupportButton = () => {
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [tab, setTab] = useState<"menu" | "feedback">("menu");
  useCloseOnClickOutside(isSupportOpen, setIsSupportOpen, "support-popup");
  const nodeRef = useRef(null);
  const style = getStyle(tab);

  useEffect(() => {
    if (!isSupportOpen) {
      setTimeout(() => {
        setTab("menu");
      }, 200);
    }
  }, [isSupportOpen]);

  return (
    <div css={style.wrapper}>
      <CSSTransition
        nodeRef={nodeRef}
        in={isSupportOpen}
        timeout={200}
        unmountOnExit
        classNames="support-popup-transition"
      >
        <div css={style.popup} id="support-popup" ref={nodeRef}>
          {tab === "menu" && <SupportMenu onClick={() => setTab("feedback")} />}
          {tab === "feedback" && (
            <SupportFeedback
              onClose={() => setIsSupportOpen(false)}
              onGoBack={() => setTab("menu")}
            />
          )}
        </div>
      </CSSTransition>
      <ButtonWrapper
        onClick={() => setIsSupportOpen(true)}
        customCss={style.button}
        ariaLabel="Support"
      >
        <AnnotationQuestionIcon stroke={uiGray[70]} width={16} height={16} />
        <div className="support-label" css={style.supportLabel}>
          <Typography weight="bold" color={uiGray[70]}>
            Support
          </Typography>
        </div>
      </ButtonWrapper>
    </div>
  );
};
