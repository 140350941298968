import { AuthError, PostgrestError } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";

import { supabase } from "apis/supabaseClient";

export const addSecondaryEmailAddress = async (
  secondaryEmailAddress: string
): Promise<{ error: string | null }> => {
  const { error } = await supabase.from("addresses").insert({
    address_id: secondaryEmailAddress,
    type: "email",
    ordinal: "secondary",
  });

  if (error?.code === "23505") {
    return { error: "This email address is already in use" };
  }

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return { error: null };
};

export const findSecondaryEmailAddress = async (
  userId: string
): Promise<string | undefined> => {
  const { data, error } = await supabase
    .from("addresses")
    .select("address_id")
    .eq("user_id", userId)
    .eq("type", "email")
    .eq("ordinal", "secondary");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  // catch if there is no secondary email address
  if (data.length === 0) {
    return undefined;
  }

  return data[0].address_id;
};

type ExistingAccount = {
  existingUserId: string;
  existingUserEmailLogin: string;
  loginProvider: string;
};

export const findExistingAccountByEmail = async (
  emailAddress: string,
  userId: string
): Promise<ExistingAccount | null> => {
  const { data, error } = await supabase.rpc("find_existing_account_by_email", {
    email: emailAddress,
    user_id: userId,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  if (data.length > 1) {
    throw new Error("Expected 0 or 1 account, but found more");
  }

  if (data.length === 0) return null;

  return {
    existingUserId: data[0].existing_user_id,
    existingUserEmailLogin: data[0].existing_user_email_login,
    loginProvider: data[0].login_provider,
  };
};

export const deleteSecondaryEmailAddress = async (
  userId: string
): Promise<{ error: PostgrestError | null }> => {
  const { error } = await supabase
    .from("addresses")
    .delete()
    .eq("user_id", userId)
    .eq("type", "email")
    .eq("ordinal", "secondary");

  return { error };
};

export const changePrimaryAddress = async (
  userId: string,
  newPrimaryAddress: string
): Promise<{ error: PostgrestError | AuthError | null }> => {
  // change current primary to secondary
  const { error } = await supabase
    .from("addresses")
    .update({ ordinal: "secondary" })
    .eq("user_id", userId)
    .eq("type", "email")
    .eq("ordinal", "primary");

  if (error) {
    throw new Error(error.message);
  }

  // change new primary to primary
  const { error: error2 } = await supabase
    .from("addresses")
    .update({ ordinal: "primary" })
    .eq("user_id", userId)
    .eq("type", "email")
    .eq("address_id", newPrimaryAddress);

  if (error2) {
    throw new Error(error2.message);
  }

  // change supabase auth email
  const { error: error3 } = await supabase.auth.updateUser({
    email: newPrimaryAddress,
  });

  return { error: error3 };
};
