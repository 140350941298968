import { createSlice } from "@reduxjs/toolkit";

import { Chat, ChatMessage } from "apis/chat";

import { Action, State } from "./store";

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chats: {} as Record<string, { chat: Chat; chatMessages: ChatMessage[] }>,
    assistantMessagesCount: { receivedCount: undefined } as {
      receivedCount?: number;
    },
  },
  reducers: {
    addChatInfo: (
      state,
      action: Action<{ chat: Chat; chatMessages: ChatMessage[] }>
    ) => {
      state.chats[action.payload.chat.id] = action.payload;
    },
    addChatMessages: (state, action: Action<ChatMessage[]>) => {
      const chatId = action.payload[0].chatId;
      const chatInfo = state.chats[chatId];
      state.chats[chatId] = {
        ...chatInfo,
        chatMessages: [...chatInfo.chatMessages, ...action.payload],
      };
    },
    addChatMessageReceivedCount: (state, action: Action<number>) => {
      state.assistantMessagesCount.receivedCount = action.payload;
    },
    rateMessage: (
      state,
      action: Action<{
        chatId: string;
        messageId: string;
        rating: -1 | 1 | undefined;
      }>
    ) => {
      const { chatId, messageId, rating } = action.payload;
      const chatMessages = state.chats[chatId].chatMessages;
      state.chats[chatId].chatMessages = chatMessages.map((message) =>
        message.id === messageId
          ? {
              ...message,
              rating,
              ratedAt: rating ? new Date().toISOString() : undefined,
            }
          : message
      );
    },
  },
});

export const {
  addChatInfo,
  addChatMessages,
  addChatMessageReceivedCount,
  rateMessage,
} = chatSlice.actions;

export const selectChat =
  (chatId?: string) =>
  (state: State): { chat: Chat; chatMessages: ChatMessage[] } | undefined => {
    if (!chatId) return undefined;
    return state.chat.chats[chatId];
  };

export const selectChatResponseReceivedCount = (
  state: State
): number | undefined => {
  return state.chat.assistantMessagesCount.receivedCount;
};

export default chatSlice.reducer;
