import { useState } from "react";

import { css } from "@emotion/react";

import { ArrowLeftFilledIcon } from "Icons/ArrowLeftFilled";
import { XCloseIcon } from "Icons/XClose";

import { Typography } from "components/shared/library/Typography";

import { useAlert } from "contexts/alertContext";
import { useAuth } from "contexts/authContext";

import { submitFeedback } from "apis/feedback";

import { purple, uiGray, white } from "constants/colors";

import { Button } from "../library/Button";
import { ButtonWrapper } from "../library/ButtonWrapper";
import { SatisfactionRatingField } from "../library/SatisfactionRatingField";
import { TextArea } from "../library/TextArea";

const style = {
  content: css({
    display: "flex",
    flexDirection: "column",
    height: "-webkit-fill-available",
  }),
  header: css({
    backgroundColor: purple[600],
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  form: css({
    padding: 20,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  button: css({
    borderTop: `1px solid ${uiGray[10]}`,
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  }),
  textAreaLabel: css({ fontSize: 14 }),
  textAreaTextArea: css({ fontSize: 14, maxHeight: 160 }),
};

type Props = {
  onClose: () => void;
  onGoBack: () => void;
};
export const SupportFeedback = ({ onClose, onGoBack }: Props) => {
  const [rating, setRating] = useState<number | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const { createAlert } = useAlert();
  const { user } = useAuth();
  return (
    <div css={style.content}>
      <div css={style.header}>
        <ButtonWrapper ariaLabel="Back" onClick={onGoBack}>
          <ArrowLeftFilledIcon stroke={white} width={16} height={16} />
        </ButtonWrapper>
        <Typography weight="bold" color={white}>
          Provide feedback
        </Typography>
        <ButtonWrapper ariaLabel="Close" onClick={onClose}>
          <XCloseIcon stroke={white} width={16} height={16} />
        </ButtonWrapper>
      </div>
      <div css={style.form}>
        <SatisfactionRatingField onChange={setRating} value={rating} />
        {rating && (
          <TextArea
            onChange={setDescription}
            value={description || ""}
            label={"Anything you'd like to share?"}
            customCss={{
              label: style.textAreaLabel,
              textArea: style.textAreaTextArea,
            }}
            displayLabel
            isOptional
            placeholder="Enter description"
            rows={5}
            resize="vertical"
            maxChar={1000}
          />
        )}
      </div>
      <div css={style.button}>
        <Button
          onClick={async () => {
            if (!rating) return;
            const { error } = await submitFeedback({
              rating,
              userId: user?.id,
              description,
            });
            return !error;
          }}
          onClickFail={() =>
            createAlert(
              "error",
              "Something went wrong while submitting your feedback"
            )
          }
          onClickSuccess={() => {
            createAlert("success", "Thank you for your feedback");
            onClose();
          }}
          disabled={!rating}
          size="medium"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
