import { AuthChangeEvent, Session, createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL || "test_url";
const supabaseAnonKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY || "test_key";

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const refreshTokenKey = "supabase-auth-refresh-token";
export const accessTokenKey = "supabase-auth-access-token";

export const addAuthTokensToCookies = (
  event: AuthChangeEvent,
  session: Session | null
) => {
  if (event === "SIGNED_OUT") {
    // delete cookies on sign out
    const expires = new Date(0).toUTCString();
    document.cookie = `${accessTokenKey}=; path=/; expires=${expires}; SameSite=Lax; secure`;
    document.cookie = `${refreshTokenKey}=; path=/; expires=${expires}; SameSite=Lax; secure`;
  } else if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED") {
    // add cookies on sign in
    const maxAge = 100 * 24 * 60 * 60; // 100 days, random number
    document.cookie = `${accessTokenKey}=${session?.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
    document.cookie = `${refreshTokenKey}=${session?.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
  }
};

export const getUserIdFromCookies = async (cookies: {
  [x: string]: any;
}): Promise<string | undefined> => {
  const refreshToken = cookies[refreshTokenKey];
  const accessToken = cookies[accessTokenKey];

  let userId: string | undefined = undefined;
  if (refreshToken && accessToken) {
    const session = await supabase.auth.setSession({
      refresh_token: refreshToken,
      access_token: accessToken,
    });

    userId = session.data.user?.id;
  }

  return userId;
};
