import { createSlice } from "@reduxjs/toolkit";

import { Post } from "apis/posts";

import { Action } from "./store";

export const postIndexSlice = createSlice({
  name: "postIndex",
  initialState: {
    value: {} as Record<string, Post>,
  },
  reducers: {
    upsertPost: (state, action: Action<Post>) => {
      state.value[action.payload.id] = action.payload;
    },
  },
});

export const { upsertPost } = postIndexSlice.actions;

export default postIndexSlice.reducer;
