import { css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";

import { uiGray } from "constants/colors";

type Props = {
  value: string;
  maxChar: number;
};

const style = {
  counter: css({ marginTop: 6, marginRight: 4, textAlign: "end" }),
};

export const MaxCharCounter = ({ value, maxChar }: Props) => {
  return (
    <Typography
      tag="p"
      color={uiGray[60]}
      size="XXS"
      customCss={style.counter}
    >{`${value.length}/${maxChar}`}</Typography>
  );
};
