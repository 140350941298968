export const EnglishTranslation = {
  // src/components/index/DeletedCommentBubble.tsx
  "Content Unavailable": "Content Unavailable",
  "The original comment has been removed and is no longer accessible":
    "The original comment has been removed and is no longer accessible",
  // src/components/index/DeleteCommentAction.tsx
  "Deleting this comment will remove it permanently from the post. However, any replies will remain visible.":
    "Deleting this comment will remove it permanently from the post. However, any replies will remain visible.",
  "Successfully deleted comment": "Successfully deleted comment",
  "Something went wrong when deleting your comment":
    "Something went wrong when deleting your comment",
  "Delete comment": "Delete comment",
  // src/pages/404.tsx
  "We couldn't find the page you're looking for":
    "We couldn't find the page you're looking for",
  "Oops - Page Not Found": "Oops - Page Not Found",
  "Take me home": "Take me home",
  // src/pages/500.tsx
  "Sorry, this is not working properly. We've recorded an issue and will work to fix it.":
    "Sorry, this is not working properly. We've recorded an issue and will work to fix it.",
  "500 - Oops, Something went wrong": "500 - Oops, Something went wrong",
  // src/components/shared/experiences/RequestVerificationModal.tsx
  "Once verified, the verification will display on your profile. If you edit your experience, your verifications will reset and you'll have to request it again. Please review your experience before sending a request.":
    "Once verified, the verification will display on your profile. If you edit your experience, your verifications will reset and you'll have to request it again. Please review your experience before sending a request.",
  "Request verification": "Request verification",
  "Send request": "Send request",
  "Successfully verified experience": "Successfully verified experience",
  "By verifying your experience, your profile can become more credible":
    "By verifying your experience, your profile can become more credible",
  "Write a custom message": "Write a custom message",
  "i.e., Can you please verify my experience at... ":
    "i.e., Can you please verify my experience at... ",
  "Something went wrong requesting verification":
    "Something went wrong requesting verification",
  "Verification request sent": "Verification request sent",
  // src/components/shared/experiences/VerifyModal.tsx
  "Are you sure you want to verify?": "Are you sure you want to verify?",
  "Yes, verify experience": "Yes, verify experience",
  "Something went wrong verifying experience":
    "Something went wrong verifying experience",
  "By verifying the experience you are confirming that their employment details are true and accurate. Please ensure that the details are accurate before continuing.":
    "By verifying the experience you are confirming that their employment details are true and accurate. Please ensure that the details are accurate before continuing.",
  // src/components/search/SearchBar.tsx
  "See all results": "See all results",
};
