import React, { useContext } from "react";

interface ImageOptions {
  unoptimized: boolean;
}

interface ProviderProps extends ImageOptions {
  children: React.ReactNode;
}

const ImageOptimisationContext = React.createContext<ImageOptions>({
  unoptimized: false,
});

// The next/Image component works on storybook only when unoptimized.
// However out of storybook, we want the image to be optimized.
// This context keeps track of when images should or not be optimized
// If there is no provider, this value will be false.
export const ImageOptimisationProvider = ({
  children,
  unoptimized,
}: ProviderProps) => {
  return (
    <ImageOptimisationContext.Provider value={{ unoptimized }}>
      {children}
    </ImageOptimisationContext.Provider>
  );
};

export const useImageOptimisation = () => useContext(ImageOptimisationContext);
