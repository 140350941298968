import { Loading01Icon } from "Icons/Loading01";
import { IconProps, IconType } from "Icons/types";

import { css } from "@emotion/react";

import { ROTATE_ICON_ANIMATION } from "constants/animations";

const style = {
  rotate: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: ROTATE_ICON_ANIMATION,
  }),
};

export const SpinningIcon = ({
  Icon = Loading01Icon,
  ...props
}: { Icon?: IconType } & IconProps) => {
  return (
    <div css={style.rotate} data-testid="spinning-icon">
      <Icon {...props} />
    </div>
  );
};
